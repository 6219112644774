import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { InfoText } from "../../../common/InfoText"
import { ArticleTitle } from "./ArticleTitle"

interface ArticleInfoProps {
    articleNumber: string
    articleName: string
}

export const ArticleInfo = ({
    articleNumber,
    articleName
}: ArticleInfoProps) => {
    const { t } = useTranslation()

    // Art.-Nr. 1234566
    const composedArticleNumber = t("common:articlePositions.articleNumber", {
        articleNumber
    })

    return (
        <Stack
            sx={{
                zIndex: 1,
                width: "18.75rem",
                rowGap: "10px",
                justifyContent: "space-between",
                padding: ".5rem 0"
            }}
        >
            <InfoText text={composedArticleNumber} />
            <ArticleTitle text={articleName} />
        </Stack>
    )
}
