import { ParameterTO } from "@encoway/c-services-js-client"

const DASH = "\u2014"
export const useValueToPresent = (parameterTO: ParameterTO) => {
    const { selectedValues, displayUnit } = parameterTO
    const selectedValue = selectedValues && selectedValues[0]
    const valueToDisplay =
        selectedValue && selectedValue.translatedValue !== "NO_VALUE"
            ? selectedValue.translatedValue
            : DASH

    if (displayUnit) {
        // example: "0,006 kW" or "<dash> kw"
        return `${valueToDisplay} ${displayUnit.unit}`
    }

    // example: "0,06" or "<dash>"
    return valueToDisplay
}
