import {
    ComponentName as ApplicationComponentName,
    registerDefaultApplicationComponents
} from "@encoway/cui-application-components"
import {
    ComponentName as ConfiguratorComponentName,
    registerDefaultConfiguratorComponents
} from "@encoway/cui-configurator-components"

import { MyDetailDropdown } from "./configurator/MyDetailDropdown"
import { MyDropdown } from "./configurator/MyDropdown"
import { MyFilterDropdown } from "./configurator/MyFilterDropdown"
import { MyImageButton } from "./configurator/MyImageButton"
import { MySection } from "./configurator/MySection"
import { MyTab } from "./configurator/MyTab"
import { MyTabs } from "./configurator/MyTabs"
import { MyTabsFooter } from "./configurator/MyTabsFooter"
import { MyValuePresenter } from "./configurator/MyValuePresenter"
import { ProductSelection } from "./configurator/productselection/ProductSelection"

export const initComponents = () => {
    registerDefaultApplicationComponents().override(
        ApplicationComponentName.Header,
        () => null
    )
    registerDefaultConfiguratorComponents()
        .override(ConfiguratorComponentName.NotReadyCount, () => null)
        .override(ConfiguratorComponentName.Dropdown, MyDropdown)
        .override(ConfiguratorComponentName.FilterDropdown, MyFilterDropdown)
        .override(ConfiguratorComponentName.StateIcon, () => null)
        .override(ConfiguratorComponentName.TabsFooter, MyTabsFooter)
        .override(ConfiguratorComponentName.Section, MySection)
        .override(ConfiguratorComponentName.TabButton, () => null)
        .override(ConfiguratorComponentName.TabsList, () => null)
        .override(ConfiguratorComponentName.Tab, MyTab)
        .override(ConfiguratorComponentName.Tabs, MyTabs)
        .override(ConfiguratorComponentName.ImageButton, MyImageButton)
        .override(ConfiguratorComponentName.DetailDropdown, MyDetailDropdown)
        .override("productselection", ProductSelection)
        .override("valuePresenter", MyValuePresenter)
}
