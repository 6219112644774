import { CssBaseline, ThemeProvider } from "@mui/material"
import React from "react"
import { RouterProvider } from "react-router-dom"

import { useInitApplication } from "./app/initApplication.hooks"
import { router } from "./app/router"
import { ConfigurationSession } from "./features/configuration/ConfigurationSession"
import { useMuiTheme } from "./theme/themes.hooks"

export const App = () => {
    const { initialized, initializationError } = useInitApplication()
    const muiTheme = useMuiTheme()

    if (initializationError) {
        console.error(initializationError)
        return <div>{initializationError}</div>
    }

    if (!initialized) {
        return null
    }

    return (
        <ConfigurationSession>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                <RouterProvider router={router()} />
            </ThemeProvider>
        </ConfigurationSession>
    )
}
