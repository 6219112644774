import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { SecondaryButton } from "../../../common"
import { DropDownButton } from "./DropDownButton"

interface StandardActionsProps {
    onRemove: () => void
}

const StandardActions = ({ onRemove }: StandardActionsProps) => {
    const { t } = useTranslation()

    return (
        <>
            <DropDownButton
                text={t("common:articlePositions.actionsButton")}
                sx={{
                    minWidth: "initial"
                }}
            />
            <SecondaryButton
                onClick={onRemove}
                sx={{
                    padding: 0,
                    minWidth: "initial",
                    width: "2.5rem"
                }}
            >
                <DeleteOutlineIcon />
            </SecondaryButton>
        </>
    )
}

interface DisabledActionsProps {
    onRestore: () => void
}

const DisabledActions = ({ onRestore }: DisabledActionsProps) => {
    const { t } = useTranslation()

    return (
        <SecondaryButton
            onClick={onRestore}
            sx={{
                padding: 0,
                zIndex: 1,
                minWidth: "initial",
                // combined width of "Aktionen"-Dropdown and "Remove-Icon"-Button
                // to avoid jumping buttons
                width: "168px"
            }}
        >
            {t("common:restore")}
        </SecondaryButton>
    )
}

interface ArticleActionsProps {
    disabled: boolean
    onRemove: () => void
    onRestore: () => void
}

export const ArticleActions = ({
    disabled,
    onRemove,
    onRestore
}: ArticleActionsProps) => {
    return (
        <Stack direction="row" sx={{ gap: "8px" }}>
            {disabled ? (
                <DisabledActions onRestore={onRestore} />
            ) : (
                <StandardActions onRemove={onRemove} />
            )}
        </Stack>
    )
}
