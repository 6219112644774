import { ParameterTO } from "@encoway/c-services-js-client"

export const getSubConfigurationId = (
    parameter: ParameterTO
): string | null => {
    return (
        parameter.selectedValues?.[0]?.properties[
            "configuration_service.linked.configuration_id"
        ] || null
    )
}
