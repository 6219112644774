import { Button } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { useCreateConfigurationCodeMutation } from "../../api/configurationCodesAPI"
import { selectConfigurationId } from "../../app/configuratorSlice"
import { useAppSelector } from "../../app/hooks"
import { ConfiCodeSavePopover } from "./ConfiCodeSavePopover"

export const ConfiCodeSaveButton = () => {
    const theme = useTheme()
    const { t } = useTranslation()
    const configurationId = useAppSelector(selectConfigurationId)
    const [popoverAnchor, setPopoverAnchor] =
        useState<HTMLButtonElement | null>(null)
    const [confiCode, setConfiCode] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [createConfigurationCode] = useCreateConfigurationCodeMutation()

    const createConfiCode = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setLoading(true)
        setError(false)
        setPopoverAnchor(event.currentTarget)

        if (configurationId) {
            createConfigurationCode(configurationId).then(
                (result) => {
                    if ("data" in result) {
                        setConfiCode(result.data)
                        setLoading(false)
                    } else {
                        setError(true)
                        setLoading(false)
                    }
                },
                () => {
                    setError(true)
                    setLoading(false)
                }
            )
        } else {
            setError(true)
            setLoading(false)
        }
    }

    return (
        <>
            <Button
                disableElevation
                disableRipple
                variant="text"
                onClick={createConfiCode}
                sx={{
                    "&.MuiButtonBase-root:hover": {
                        backgroundColor: "transparent !important"
                    },
                    color: theme.palette.primary.main,
                    fontSize: "0.9em",
                    fontWeight: 600,
                    padding: "0em 1em",
                    cursor: "pointer",
                    textDecoration: "none",
                    textTransform: "capitalize"
                }}
            >
                {t("common:save")}
            </Button>
            <ConfiCodeSavePopover
                confiCode={confiCode}
                anchor={popoverAnchor}
                setAnchor={setPopoverAnchor}
                loading={loading}
                error={error}
            />
        </>
    )
}
