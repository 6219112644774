import { ITheme } from "@fluentui/react"
import { getTheme, mergeStyles } from "@fluentui/react/lib/Styling"
import { IRenderFunction } from "@fluentui/react/lib/Utilities"
import classNames from "classnames"
import React from "react"

import {
    ImageButton,
    ImageButtonProps
} from "@encoway/cui-configurator-components"
import { ImageButtonOption } from "@encoway/cui-configurator-components/src/components/ImageButton/ImageButton.types"

import { useCustomColors } from "../../../../../theme/themes.hooks"
import { CustomColors } from "../../../../../theme/themes.types"

const createCellStyles = (theme: ITheme, customColors: CustomColors) => {
    return mergeStyles({
        outline: 0,
        border: `1px solid ${customColors.mediumLightBlue}`,
        "&.isSelected": {
            borderColor: theme.palette?.themePrimary
        },
        "&.notSelectable:not(.isSelected)": {
            borderColor: customColors.lightBlue
        },
        borderRadius: "2px",
        color: theme.palette?.black,
        ":hover": {
            borderColor: `${theme.palette?.themePrimary} !important`,
            transition: "0.3s"
        },
        ".ms-Image": {
            borderBottom: `1px solid ${customColors.mediumLightBlue}`
        },
        "&.isSelected .ms-Image": {
            borderBottomColor: theme.palette?.themePrimary
        },
        "&.notSelectable:not(.isSelected) .ms-Image": {
            borderBottomColor: customColors.lightBlue
        },
        ":hover .ms-Image": {
            borderBottomColor: `${theme.palette?.themePrimary} !important`,
            transition: "0.3s"
        },
        ".ms-Image-image :hover": {
            border: "0 !important"
        }
    })
}

export const MyImageButton = (props: ImageButtonProps) => {
    const { data, onValueChanged } = props
    const theme = getTheme()
    const customColors = useCustomColors()

    const cellStyles = createCellStyles(theme, customColors)

    const onRenderCell: IRenderFunction<ImageButtonOption> = (
        props,
        defaultRender
    ) => {
        if (!defaultRender || !props) {
            return null
        }

        const { selected, selectable } = props
        return (
            <div
                key={props.value}
                className={classNames(cellStyles, {
                    isSelected: selected,
                    notSelectable: !selectable
                })}
            >
                {defaultRender(props)}
            </div>
        )
    }

    return (
        <ImageButton
            {...props}
            data={data}
            onValueChanged={onValueChanged}
            onRenderCell={onRenderCell}
            minOptionWidth={180}
            maxOptionWidth={180}
            styles={{
                option: {
                    ":focus": {
                        outline: 0
                    }
                },
                imageContainer: {
                    border: 0
                },
                imageContainerNotReady: {
                    border: 0
                },
                imageContainerSelected: {
                    border: 0
                },
                captionText: {
                    color: theme.palette?.black
                },
                captionTextSelected: {
                    color: theme.palette?.themePrimary
                },
                captionTextNotSelected: {
                    color: theme.palette?.black
                },
                upperCaption: {
                    div: {
                        hover: {
                            color: theme.palette?.black
                        }
                    }
                }
            }}
        />
    )
}
