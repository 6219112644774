import { getTheme, Image, ImageFit, Stack, Text } from "@fluentui/react"
import React from "react"

interface BrandCardProps {
    name: string
    logo: string
    selected: boolean
    onClick: () => void
}

export const BrandCard = ({
    name,
    logo,
    selected,
    onClick
}: BrandCardProps) => {
    const theme = getTheme()

    const borderColor = selected
        ? theme.palette.themePrimary
        : theme.palette.neutralQuaternaryAlt

    return (
        <Stack
            onClick={onClick}
            style={{
                width: 200,
                cursor: "pointer",
                border: "1px solid",
                borderColor,
                justifyContent: "start",
                alignItems: "center"
            }}
        >
            <Image
                src={logo}
                alt={name}
                width={120}
                height={120}
                imageFit={ImageFit.contain}
            />
            <Text
                style={{
                    fontWeight: "bold",
                    padding: "0.5em"
                }}
            >
                {name}
            </Text>
        </Stack>
    )
}
