import { merge } from "lodash"

import {
    IAppSettings,
    translations as configurationTranslations
} from "@encoway/cui-application-components"
import { translations as applicationTranslations } from "@encoway/cui-configurator-components"

import { translations as customTranslations } from "./cui/translations"

export const loadSettings = (
    token: () => string,
    articleName: string,
    configurationId?: string
): IAppSettings => ({
    locale: "de-DE",
    configuration: {
        settings: {},
        options: {
            articleName,
            configurationId,
            linkedConfiguration: "true"
        },
        token,
        includeCredentials: true
    },
    oauth2: {
        applicationId: "wiedemann-sales",
        oath2ServerAuthenticationUrl: window.sso.authorizationEndpointUrl
    },
    translations: merge(
        {},
        applicationTranslations,
        configurationTranslations,
        customTranslations
    )
})
