import React from "react"
import { createBrowserRouter } from "react-router-dom"

import { Layout } from "../Layout"
import { Composition } from "../features/composition/Composition"
import { Configuration } from "../features/configuration/Configuration"

export const router = () =>
    createBrowserRouter([
        {
            path: "/",
            element: <Layout />,
            children: [
                {
                    index: true,
                    element: <Configuration />
                },
                { path: "/composition", element: <Composition /> }
            ]
        }
    ])
