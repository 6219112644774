import { Stack, Text } from "@fluentui/react"
import { getTheme } from "@fluentui/react/lib/Styling"
import React from "react"
import { useTranslation } from "react-i18next"

import { InfoText } from "./InfoText"

interface ArticlePricePerUnitProps {
    preTax: number
    postTax: number
    unitSize: number
    unitDescriptor?: string
}

export const ArticlePricePerUnit = ({
    preTax,
    postTax,
    unitSize
}: ArticlePricePerUnitProps) => {
    const theme = getTheme()
    const { t } = useTranslation()

    // "Brutto: 6.187,00 € / 1,00 St"
    const composedPreTax = t("common:articlePositions.preTax", {
        preTax,
        amount: unitSize
    })

    // "4.640,25€"
    const composedTotal = t("common:currencyTemplateEUR", {
        val: postTax
    })

    // " / 1,00 St"
    const composedPerUnit = t("common:articlePositions.piece", {
        amount: unitSize
    })

    return (
        <Stack
            style={{
                gap: "7px"
            }}
        >
            <InfoText text={composedPreTax} />
            <Stack
                horizontal
                verticalAlign={"center"}
                style={{
                    gap: "7px"
                }}
            >
                <Text
                    style={{
                        fontWeight: "bold",
                        color: theme.palette.themePrimary
                    }}
                >
                    {composedTotal}
                </Text>
                <InfoText text={composedPerUnit} />
            </Stack>
        </Stack>
    )
}
