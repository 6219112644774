import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { InfoText } from "../../../common/InfoText"

interface ArticlePricePerUnitProps {
    preTax: number
    postTax: number
}

export const ArticlePricePerUnit = ({
    preTax,
    postTax
}: ArticlePricePerUnitProps) => {
    const { t } = useTranslation()

    // "Brutto: 6.187,00 € / 1,00 St"
    const composedPreTax = t("common:articlePositions.preTax", {
        preTax,
        amount: 1
    })

    // "Ihr Preis: 4.640,25 € / 1,00 St"
    const composedPostTax = t("common:articlePositions.postTax", {
        postTax,
        amount: 1
    })

    return (
        <Stack padding="0 1em">
            <InfoText text={composedPreTax} />
            <InfoText text={composedPostTax} />
        </Stack>
    )
}
