import { Box, Stack, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import {
    selectArticleAmount,
    setArticleAmount
} from "../../../app/compositionSlice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Counter, Image } from "../../../common"
import { InfoText } from "../../../common/InfoText"
import { useCustomColors } from "../../../theme/themes.hooks"
import { CompositionProduct } from "../composition.types"
import { ArticleActions } from "./ArticleActions"
import { ArticleInfo } from "./ArticleInfo"
import { ArticlePricePerUnit } from "./ArticlePricePerUnit"

interface DisabledOverlayProps {
    disabled: boolean
}

const DisabledOverlay = ({ disabled }: DisabledOverlayProps) => {
    const customColors = useCustomColors()

    const disabledOverlayCSS = {
        display: "block",
        width: "100%",
        height: "100%",
        position: "absolute",
        padding: 0,
        zIndex: 0,
        backgroundColor: customColors.mediumLightBlue,
        opacity: "40%"
    }

    const CSS = disabled ? disabledOverlayCSS : {}

    return <Box sx={CSS} />
}

interface ArticleProps {
    article: CompositionProduct
}

// Within this component the zIndex is used to "pop up" the necessary
// elements when the component is disabled or articleAmount is zero.
// zIndex is used in:
// - DisabledOverlay component, this file (adove)
// - Image component, this file (Article component)
// - DisabledActions component, nested within ArticleActions (in Article component)
export const Article = ({ article }: ArticleProps) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const [disabled, setDisabled] = useState(false)

    const { manufacturerId, manufacturerPid, articleName, price, image } =
        article
    const parsedPrice = Number(price)

    const dispatch = useAppDispatch()
    const piid = `${manufacturerId}|${manufacturerPid}`
    const articleAmount = useAppSelector(selectArticleAmount(piid))

    // "Brutto Gesamt 1,00 € / 1,00 St" (über Gesamt)
    const composedTotal = t("common:articlePositions.preTaxTotal", {
        total: articleAmount * parsedPrice,
        amount: articleAmount
    })

    // Gesamt 4.640,25€
    const composedTotalReduced = t("common:total", {
        total: articleAmount * parsedPrice
    })

    const articleIsDisabled = () => {
        return disabled || articleAmount === 0
    }

    const handleRemove = () => {
        setDisabled(true)
        dispatch(setArticleAmount({ piid, amount: 0 }))
    }

    const handleRestore = () => {
        setDisabled(false)

        if (articleAmount === 0) {
            dispatch(setArticleAmount({ piid, amount: 1 }))
        }
    }

    return (
        <Box sx={{ position: "relative" }}>
            <DisabledOverlay disabled={articleIsDisabled()} />

            <Stack
                direction="row"
                sx={{
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    padding: "1em",
                    border: "1px solid",
                    borderColor: theme.palette.secondary.main
                }}
            >
                <Image src={image} sx={{ zIndex: 1 }} />

                <ArticleInfo
                    articleName={articleName}
                    articleNumber={manufacturerPid}
                />

                <Stack direction="row">
                    <Counter
                        piid={piid}
                        width="6.25rem"
                        restore={handleRestore}
                        inlayText={t("common:piece").toString()}
                        amountAlignment="end"
                    />

                    <ArticlePricePerUnit
                        preTax={parsedPrice}
                        postTax={parsedPrice}
                    />
                </Stack>

                <Stack justifyContent="space-between">
                    <Stack>
                        <InfoText text={composedTotal} />
                        <Typography
                            fontWeight="bold"
                            color={theme.palette.primary.main}
                        >
                            {composedTotalReduced}
                        </Typography>
                    </Stack>

                    <ArticleActions
                        disabled={articleIsDisabled()}
                        onRemove={handleRemove}
                        onRestore={handleRestore}
                    />
                </Stack>
            </Stack>
        </Box>
    )
}
