import { createTheme as initCuiTheme, Theme as CuiTheme } from "@fluentui/react"
import {
    createTheme as initMuiTheme,
    Theme as MuiTheme
} from "@mui/material/styles"

import { useAppSelector } from "../app/hooks"
import { defaultThemeDefinition, loadTheme } from "./themes"

export const useCustomColors = () => {
    const houseId = useAppSelector((state) => state.configurator.houseId)
    return (
        (houseId && loadTheme(houseId).customColors) ||
        defaultThemeDefinition.customColors
    )
}

export const useMuiPalette = () => {
    const houseId = useAppSelector((state) => state.configurator.houseId)
    return (
        (houseId && loadTheme(houseId).muiThemeDefinition.palette) ||
        defaultThemeDefinition.muiThemeDefinition.palette
    )
}

export const useCuiPalette = () => {
    const houseId = useAppSelector((state) => state.configurator.houseId)
    return (
        (houseId && loadTheme(houseId).cuiThemeDefinition.palette) ||
        defaultThemeDefinition.cuiThemeDefinition.palette
    )
}

export const useMuiTheme = (): MuiTheme => {
    const houseId = useAppSelector((state) => state.configurator.houseId)
    return (
        (houseId && initMuiTheme(loadTheme(houseId).muiThemeDefinition)) ||
        initMuiTheme(defaultThemeDefinition.muiThemeDefinition)
    )
}

export const useCuiTheme = (): CuiTheme => {
    const houseId = useAppSelector((state) => state.configurator.houseId)
    return (
        (houseId && initCuiTheme(loadTheme(houseId).cuiThemeDefinition)) ||
        initCuiTheme(defaultThemeDefinition.cuiThemeDefinition)
    )
}
