import { ISeparatorStyles, Separator, Stack, Text } from "@fluentui/react"
import React from "react"
import { useTranslation } from "react-i18next"

import { ProductSelectionProduct } from "../../../../../../../api/deepSearchAPI.types"
import { Article } from "./Article"

const separatorStyles: ISeparatorStyles = {
    content: {},
    root: {
        padding: 0,
        marginBottom: "1em"
    }
}

interface ArticleListProps {
    articles: Array<ProductSelectionProduct>
    hitCount: number
}

export const ArticleList = ({ articles, hitCount }: ArticleListProps) => {
    const { t } = useTranslation()

    return (
        <Stack>
            <Text
                style={{
                    lineHeight: 1,
                    fontSize: "1.2em",
                    fontWeight: "bold"
                }}
            >
                {t("common:articlePositions.article", {
                    count: articles.length,
                    hitCount
                })}
            </Text>
            <Separator styles={separatorStyles} />
            <Stack>
                {articles.map((article) => (
                    <Article key={article.articleName} article={article} />
                ))}
            </Stack>
        </Stack>
    )
}
