import {
    Stack,
    Dropdown,
    getTheme,
    IDropdownOption,
    IDropdownStyles,
    ITheme
} from "@fluentui/react"
import { DefaultButton } from "@fluentui/react/lib/Button"
import React from "react"
import { useTranslation } from "react-i18next"

import { ArticleSearchFilter } from "../../../../../../../api/deepSearchAPI.types"

const dropdownStyles = (theme: ITheme): Partial<IDropdownStyles> => ({
    root: {
        width: "100%"
    },
    title: {
        border: "none",
        backgroundColor: theme.palette.neutralQuaternaryAlt
    },

    subComponentStyles: {
        panel: {},
        label: {},
        multiSelectItem: {
            label: {
                padding: 0
            },
            checkbox: {
                height: "16px",
                width: "16px",
                fontSize: "12px",
                paddingBottom: "3px"
            }
        }
    }
})

interface ArticleFilterProps {
    filter: {
        columnName: string
        columnDescr: string
        filterType: string
        filterValue: Array<{ value: string; count: number }>
    }
    updateFilter: (filter: ArticleSearchFilter) => void
}

export const ArticleFilter = ({ filter, updateFilter }: ArticleFilterProps) => {
    const { columnName, columnDescr, filterType, filterValue } = filter

    const theme = getTheme()
    const { t } = useTranslation()
    const [selectedKeys, setSelectedKeys] = React.useState<string[]>([])

    const dropdownOptions: IDropdownOption[] = filterValue.map((entry) => ({
        key: entry.value,
        text: `${entry.value} (${entry.count})`
    }))

    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption
    ): void => {
        if (!item) {
            return
        }

        setSelectedKeys((selectedKeys) => {
            return item.selected
                ? [...selectedKeys, item.key as string]
                : selectedKeys.filter((key) => key !== item.key)
        })
    }

    const applyFilter = () => {
        if (selectedKeys.length === 0) {
            return
        }

        updateFilter({
            columnName,
            filterType,
            value: selectedKeys
        })
    }

    const onRenderList = (props: any, defaultRender: any) => {
        return (
            <Stack tokens={{ padding: "1rem" }}>
                {defaultRender(props)}

                <Stack
                    horizontal
                    horizontalAlign="center"
                    tokens={{ padding: "1rem 0 0 0" }}
                >
                    <DefaultButton
                        onClick={applyFilter}
                        style={{
                            border: 0,
                            width: "100%",
                            color: theme.palette.white,
                            backgroundColor: theme.palette.themePrimary
                        }}
                    >
                        {t("common:apply")}
                    </DefaultButton>
                </Stack>
            </Stack>
        )
    }

    return (
        <Dropdown
            onRenderList={onRenderList}
            placeholder={columnDescr}
            selectedKeys={selectedKeys}
            onChange={(e, i) => {
                onChange(e, i as IDropdownOption)
            }}
            multiSelect
            options={dropdownOptions}
            styles={dropdownStyles(theme)}
        />
    )
}
