import { Text } from "@fluentui/react"
import { getTheme } from "@fluentui/react/lib/Styling"
import React from "react"

interface InfoTextProps {
    text: string
}

export const InfoText = ({ text }: InfoTextProps) => {
    const theme = getTheme()

    return (
        <Text
            style={{
                fontSize: "10px",
                color: theme.palette.neutralTertiary
            }}
        >
            {text}
        </Text>
    )
}
