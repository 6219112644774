import { theme as boernerThemeDefinition } from "./theme.boerner"
import { theme as wiedemannThemeDefinition } from "./theme.wiedemann"
import { FrontendThemeDefinition } from "./themes.types"

export const defaultThemeDefinition = wiedemannThemeDefinition

const themes: { [key: string]: FrontendThemeDefinition } = {
    wiedemann: wiedemannThemeDefinition, // https://www.wiedemann.de/
    boerner: boernerThemeDefinition, // https://www.boerner-home.de/
    elmer: defaultThemeDefinition,
    jensen: defaultThemeDefinition,
    koenig: defaultThemeDefinition,
    pietsch: defaultThemeDefinition
}

const useDefaultTheme = () => {
    console.warn("Theme fallback to default theme")
    return defaultThemeDefinition
}

export const loadTheme = (houseId: string): FrontendThemeDefinition =>
    themes[houseId] ?? useDefaultTheme()
