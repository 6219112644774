import ErrorIcon from "@mui/icons-material/Error"
import { Box, CircularProgress, Stack } from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { useLazyLoadCompositionQuery } from "../../api/compositionAPI"
import {
    initializeArticleAmounts,
    selectArticleAmounts
} from "../../app/compositionSlice"
import { selectConfigurationId } from "../../app/configuratorSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { SecondaryButton } from "../../common"
import { InfoLayout } from "../../common/InfoLayout"
import { StickyToolbar } from "./StickyToolbar"
import { ArticlePositions } from "./articles/ArticlePositions"
import { getArticleIds, getTotalPrice } from "./util"

export const Composition = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const [loadProductComposition, response] = useLazyLoadCompositionQuery()

    const dispatch = useAppDispatch()
    const configurationId = useAppSelector(selectConfigurationId)
    const articleAmounts = useAppSelector(selectArticleAmounts())

    useEffect(() => {
        if (!configurationId) {
            return
        }

        loadProductComposition(configurationId)
    }, [configurationId])

    useEffect(() => {
        if (!response.data) {
            return
        }

        const { selectedProducts, accessoryGroups } = response.data

        const articleIds = getArticleIds(selectedProducts, accessoryGroups)
        dispatch(initializeArticleAmounts(articleIds))
    }, [response.data])

    if (response.isLoading || response.isFetching || response.isUninitialized) {
        return (
            <InfoLayout>
                <CircularProgress size={15} />
                {t("common:loadingProducts")}
            </InfoLayout>
        )
    }

    if (response.isError) {
        return (
            <InfoLayout>
                <ErrorIcon fontSize="small" />
                {t("common:loadingProductsError")}
            </InfoLayout>
        )
    }

    const { selectedProducts, accessoryGroups } = response.data

    const total = getTotalPrice(
        selectedProducts,
        accessoryGroups,
        articleAmounts
    )

    return (
        <Box sx={{ padding: "0.5em 2em" }}>
            <Stack spacing={5}>
                <SecondaryButton
                    onClick={() => navigate("/")}
                    sx={{ width: "2rem" }}
                >
                    {t("common:back")}
                </SecondaryButton>

                <ArticlePositions
                    selectedProducts={selectedProducts}
                    accessoryGroups={accessoryGroups}
                />
            </Stack>
            <StickyToolbar total={total} />
        </Box>
    )
}
