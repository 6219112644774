import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

import { compositionAPI } from "../api/compositionAPI"
import { configurationCodesAPI } from "../api/configurationCodesAPI"
import { deepSearchAPI } from "../api/deepSearchAPI"
import { elbridgeConnectorAPI } from "../api/elbridgeConnectorAPI"
import compositionReducer from "./compositionSlice"
import conficodeReducer from "./conficodeSlice"
import configuratorReducer from "./configuratorSlice"

export const createStore = () =>
    configureStore({
        reducer: {
            configurator: configuratorReducer,
            conficode: conficodeReducer,
            composition: compositionReducer,
            [deepSearchAPI.reducerPath]: deepSearchAPI.reducer,
            [configurationCodesAPI.reducerPath]: configurationCodesAPI.reducer,
            [compositionAPI.reducerPath]: compositionAPI.reducer,
            [elbridgeConnectorAPI.reducerPath]: elbridgeConnectorAPI.reducer
        },
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware()
                .concat(deepSearchAPI.middleware)
                .concat(configurationCodesAPI.middleware)
                .concat(compositionAPI.middleware)
                .concat(elbridgeConnectorAPI.middleware)
    })
export const store = createStore()

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>
