import { Box, Stack, SxProps } from "@mui/material"
import React from "react"

interface ImageProps {
    src: string
    alt?: string
    sx?: SxProps
}

export const Image = ({ src, alt = "", sx }: ImageProps) => {
    return (
        <Stack justifyContent="center" sx={{ width: 100, height: 100 }}>
            <Box
                component="img"
                alt={alt}
                src={src}
                sx={{
                    display: "block",
                    margin: "0 auto",
                    ...sx
                }}
            />
        </Stack>
    )
}
