import { useEffect, useState } from "react"

import UserService from "../services/userService"
import {
    setConfiguratorId,
    setElbridgeId,
    setHouseId,
    setLang
} from "./configuratorSlice"
import { useAppDispatch } from "./hooks"

/**
 * If there is a URL parameter for a given key (e.g. configurator ID, theme ID, elbridge ID, ...),
 * store (update) the value in the session storage,
 * so redirects do longer matter.
 *
 * In any case, return the value for the given key from the session storage.
 * (This will return the value even if the URL parameter disappeared after redirects; e.g. OAuth2 login;
 * The URL parameter will be gone, but the session storage will stay)
 *
 * @param key the URL search parameter ID (also used for session storage)
 * @return the value
 */
const initializationParameter = (key: string): string | null => {
    const url = new URL(window.location.href)
    const searchParam = url.searchParams.get(key)
    searchParam && sessionStorage.setItem(key, searchParam)
    return sessionStorage.getItem(key)
}

export const useInitApplication = () => {
    const [initialized, setInitialized] = useState(false)
    const [initializationError, setInitializationError] = useState<
        string | null
    >(null)
    const dispatch = useAppDispatch()

    useEffect(() => {
        const initApp = async () => {
            try {
                const configuratorId = initializationParameter("configuratorId")
                const houseId = initializationParameter("houseId")

                if (!configuratorId) {
                    setInitializationError(
                        "Es wurde kein Konfigurator definiert"
                    )
                    return
                }

                // ELBRIDGE fields
                const elbridgeId = initializationParameter("elbridgeId")
                // Sprache analog zu BMEcat ETIM ISO 639-2, e.g. „deu“ Deutsch
                const lang = initializationParameter("lang")

                // Wait for user to be logged in
                await UserService.init()
                if (UserService.getAccessTokenFromStorage()) {
                    // Replace authorization server URL params for react router
                    history.replaceState(null, "", "/")

                    // Finalize initialization
                    dispatch(setElbridgeId(elbridgeId))
                    dispatch(setConfiguratorId(configuratorId))
                    dispatch(setHouseId(houseId))
                    dispatch(setLang(lang))
                    setInitialized(true)
                }
            } catch (e) {
                setInitializationError("Ein Fehler ist aufgetreten")
                console.error(
                    "An error occurred while initializing the application",
                    e
                )
            }
        }

        !initialized && initApp()
    }, [initialized])

    return { initialized, initializationError }
}
