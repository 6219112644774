import { Container } from "@mui/material"
import * as React from "react"
import { Outlet } from "react-router-dom"

import { Header } from "./features/header/Header"

export const Layout = () => {
    return (
        <>
            <Header />
            <Container
                sx={{
                    maxWidth: "1600px !important",
                    alignSelf: "center"
                }}
            >
                <Outlet />
            </Container>
        </>
    )
}
