import { useState } from "react"

export const useSupplierFilter = () => {
    const [supplierFilter, setSupplierFilter] = useState<Array<string>>([])

    const toggleFilterName = (
        filterName: string,
        filterList: Array<string>
    ) => {
        const filterNameInList = filterList.includes(filterName)

        if (filterNameInList) {
            return filterList.filter((entry) => entry !== filterName)
        }

        return [...filterList, filterName]
    }

    const updateSupplierFilter = (supplierName: string) => {
        setSupplierFilter((currentFilters) => {
            return toggleFilterName(supplierName, currentFilters)
        })
    }

    const resetSupplierFilter = () => setSupplierFilter([])

    return { supplierFilter, updateSupplierFilter, resetSupplierFilter }
}
