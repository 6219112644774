import { DefaultButton } from "@fluentui/react/lib/Button"
import { Stack, StackItem } from "@fluentui/react/lib/Stack"
import {
    IProcessedStyleSet,
    IStyleSet,
    mergeStyles
} from "@fluentui/react/lib/Styling"
import { Text } from "@fluentui/react/lib/Text"
import { TooltipDelay, TooltipHost } from "@fluentui/react/lib/Tooltip"
import { classNamesFunction, styled } from "@fluentui/react/lib/Utilities"
import React from "react"

import {
    ComponentName,
    ITabStyles,
    TabProps,
    TabStyles
} from "@encoway/cui-configurator-components"
import { containsVisibleParameter } from "@encoway/cui-configurator-components/dist/esm/helperFunctions"
import { ComponentFactory } from "@encoway/react-configurator"

import { MyTabCheckmark } from "./MyTab.checkmark"
import { isTabReady } from "./readyState"

/**
 * The TabWithCheckMark component is a copy of CUIs Tab component.
 * It renders the title of a ContainerTO as an MS Fluent Default Button with a Checkmark to indicate if the tab is ready.
 */
const ITabWithCheckMark = (props: TabProps) => {
    const { styles, theme, data } = props
    const classNames: IProcessedStyleSet<IStyleSet<ITabStyles>> =
        classNamesFunction()(styles, theme)
    const id = `encoway-cui-tab-${props.data.id}`

    if (!containsVisibleParameter(data)) {
        return null
    }
    const isReady = isTabReady(data)

    let className = classNames.tab
    if (!isReady) {
        className = mergeStyles(className, classNames.notReady)
    }
    if (props.selected) {
        className = mergeStyles(className, classNames.selected)
    }

    const notReadyCount = ComponentFactory.instanceOf(
        ComponentName.NotReadyCount,
        { data: props.data }
    )

    return (
        <TooltipHost
            content={props.data.translatedName}
            delay={TooltipDelay.long}
        >
            <DefaultButton
                id={id}
                className={className}
                onClick={() => props.onClick?.(props.data)}
            >
                <Stack grow={1} horizontal tokens={{ childrenGap: "0.5em" }}>
                    <StackItem
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <MyTabCheckmark
                            active={props.selected}
                            isReady={isReady}
                        />
                    </StackItem>
                    <StackItem className={classNames.text}>
                        <Text className={classNames.text} nowrap>
                            {props.data.translatedName}
                        </Text>
                    </StackItem>
                    {!isReady && notReadyCount}
                </Stack>
            </DefaultButton>
        </TooltipHost>
    )
}

/**
 * The TabWithCheckMark component is a copy of CUIs Tab component.
 * It renders the title of a ContainerTO as an MS Fluent Default Button with a Checkmark to indicate if the tab is ready.
 */
export const MyTabOverride = styled(ITabWithCheckMark, TabStyles)
MyTabOverride.displayName = "TabWithCheckMark"
