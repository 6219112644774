import { useEffect } from "react"

import { ConfigurationService, Http } from "@encoway/c-services-js-client"
import { L10n } from "@encoway/l10n"

import {
    selectConfiguratorId,
    selectConfigurationId,
    setConfigurationId
} from "../../app/configuratorSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import UserService from "../../services/userService"
import { loadSettings } from "./settings"

export const useSettings = () => {
    const configurableProductId = useAppSelector(selectConfiguratorId)
    const configurationId = useAppSelector(selectConfigurationId)
    return (
        configurableProductId &&
        loadSettings(
            () => UserService.getAccessTokenFromStorage(),
            configurableProductId,
            configurationId
        )
    )
}

/**
 * A) We need a configuration heartbeat when the CUI react components are not loaded (e.g. composition page).
 * B) We need the configuration id (created secretly inside CUIApp.tsx) to create and load configuration codes.
 *    The trick here is to create a configuration session first and then pass the configuration id to CUI via the app settings.
 */
export const useConfigurationSession = () => {
    const settings = useSettings()
    const dispatch = useAppDispatch()
    const createConfigurationService = async () => {
        if (settings) {
            const configurationService = await ConfigurationService.create(
                Http.create(settings.configuration),
                "",
                settings.configuration.options,
                L10n.currentFullLocale()
            )
            configurationService.settings(settings.configuration.settings)
            return configurationService
        }
    }

    useEffect(() => {
        createConfigurationService().then((configurationService) => {
            configurationService &&
                dispatch(setConfigurationId(configurationService.id()))
        })
    }, [])
}
