import { SxProps, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"

interface ArticleTitleProps {
    text: string
    uppercase?: boolean
    sx?: SxProps
}

export const ArticleTitle = ({
    text,
    uppercase = false,
    sx = {}
}: ArticleTitleProps) => {
    const theme = useTheme()
    return (
        <Typography
            sx={{
                fontSize: "18px",
                lineHeight: 1.2,
                fontWeight: "bold",

                letterSpacing: "-0.2px",
                color: theme.palette.text.secondary,
                ...sx,
                ...(uppercase && { textTransform: "uppercase" })
            }}
        >
            {text}
        </Typography>
    )
}
