import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import {
    Box,
    Button,
    CircularProgress,
    Popover,
    Typography
} from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
// @ts-ignore
import { CopyToClipboard } from "react-copy-to-clipboard"
import { useTranslation } from "react-i18next"

import { useCustomColors } from "../../theme/themes.hooks"

type Anchor = HTMLButtonElement | null

const Loading = () => {
    const { t } = useTranslation()
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
                size={25}
                sx={{ color: "white", marginLeft: "1em" }}
            />
            <Typography sx={{ p: 2 }}>
                {t("glossary:header.saveConfigurationLoading")}
            </Typography>
        </Box>
    )
}

const SuccessMessage = (props: { confiCode: string }) => {
    const { t } = useTranslation()
    return (
        <Box sx={{ padding: "1em" }}>
            <Typography>
                {t("glossary:header.saveConfigurationSuccess1")}
            </Typography>
            <Typography>
                {t("glossary:header.saveConfigurationSuccess2")}
            </Typography>
            <Typography fontWeight={"bold"}>{props.confiCode}</Typography>
            <CopyToClipboard text={props.confiCode}>
                <Button
                    variant="outlined"
                    startIcon={<ContentPasteIcon />}
                    sx={{
                        color: "white",
                        marginTop: "1em",
                        borderColor: "white",
                        "&:hover": {
                            borderColor: "white"
                        }
                    }}
                >
                    {t("glossary:header.copyConfiCodeToClipboard")}
                </Button>
            </CopyToClipboard>
        </Box>
    )
}

const ErrorMessage = () => {
    const { t } = useTranslation()
    return (
        <Typography sx={{ padding: "1em" }}>
            {t("glossary:header.saveConfigurationFailed")}
        </Typography>
    )
}

type Props = {
    confiCode?: string
    anchor: Anchor
    setAnchor: Dispatch<SetStateAction<Anchor>>
    loading: boolean
    error: boolean
}

export const ConfiCodeSavePopover = (props: Props) => {
    const { confiCode, anchor, setAnchor, loading, error } = props
    const customColors = useCustomColors()
    const open = Boolean(anchor)

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
        >
            <Box
                sx={{
                    color: "white",
                    backgroundColor: `${customColors?.headerPopoverBackground}`
                }}
            >
                {(error && <ErrorMessage />) ||
                    (loading && <Loading />) ||
                    (confiCode && <SuccessMessage confiCode={confiCode} />)}
            </Box>
        </Popover>
    )
}
