import { ButtonProps } from "@mui/material"
import React from "react"

import { useCustomColors } from "../theme/themes.hooks"
import { SecondaryButton } from "./index"

// TODO only used in composition
export const CounterButton = (props: ButtonProps) => {
    const customColors = useCustomColors()
    return (
        <SecondaryButton
            {...props}
            sx={{
                padding: 0,
                minWidth: 0,
                width: "2rem",
                height: "2rem",
                backgroundColor: customColors?.buttonDarkerGray
            }}
        >
            {props.children}
        </SecondaryButton>
    )
}
