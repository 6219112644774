import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { CompositionProduct } from "../composition.types"
import { Article } from "./Article"
import { Headline } from "./Headline"

interface SelectedProductsProps {
    selectedProducts: Array<CompositionProduct>
}

export const SelectedProducts = ({
    selectedProducts
}: SelectedProductsProps) => {
    const { t } = useTranslation()

    return (
        <Stack>
            <Headline
                uppercase
                text={t("common:articlePositions.yourProducts")}
                sx={{
                    marginBottom: "0.25em"
                }}
            />
            {selectedProducts.map((product) => (
                <Article
                    key={`${product.manufacturerId}|${product.manufacturerPid}`}
                    article={product}
                />
            ))}
        </Stack>
    )
}
