import { Box } from "@mui/material"
import React from "react"

import { CUI } from "./cui/CUI"

export const Configuration = () => {
    return (
        <Box>
            <Box component="main">
                <CUI />
            </Box>
        </Box>
    )
}
