import { Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

export const HeaderTitle = () => {
    const { t } = useTranslation()

    return (
        <Typography
            sx={{
                color: "white",
                fontSize: "2em"
            }}
        >
            {t("glossary:header.title")}
        </Typography>
    )
}
