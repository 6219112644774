import { Grid, Stack, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { useCustomColors } from "../../theme/themes.hooks"
import { ConfiCodeInput } from "./ConfiCodeInput"
import { ConfiCodeLoadButton } from "./ConfiCodeLoadButton"

export const ConfiCodePanel = () => {
    const customColors = useCustomColors()
    const { t } = useTranslation()

    return (
        <Grid item xs={2.5} sx={{ alignItems: "center" }}>
            <Stack
                sx={{
                    padding: "1.5em .8em .8em",
                    backgroundColor: customColors.tealDark,
                    color: "white"
                }}
            >
                <Typography
                    sx={{
                        color: "white",
                        fontSize: ".8em",
                        fontWeight: 600
                    }}
                >
                    {t("glossary:header.loadConfigurationQuestion")}
                </Typography>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: ".5em"
                    }}
                >
                    <ConfiCodeInput />
                    <ConfiCodeLoadButton />
                </div>
            </Stack>
        </Grid>
    )
}
