import { getTheme } from "@fluentui/react"
import React from "react"

import {
    DetailDropdown,
    DetailDropdownProps
} from "@encoway/cui-configurator-components"

import { dropdownStyles } from "../dropdownStyles"

export function MyDetailDropdown(props: DetailDropdownProps) {
    const theme = getTheme()

    return (
        <DetailDropdown
            {...props}
            styles={{
                ...dropdownStyles(theme)
            }}
        />
    )
}
