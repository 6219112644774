import { Stack, Typography } from "@mui/material"
import React from "react"

import {
    decrementArticleAmount,
    incrementArticleAmount,
    selectArticleAmount
} from "../app/compositionSlice"
import { useAppDispatch, useAppSelector } from "../app/hooks"
import { CounterButton, InputWithInlay } from "./index"

export interface CounterProps {
    piid: string
    width: string
    amountAlignment?: "start" | "center" | "end"
    inlayText?: string
    restore: () => void
}

// TODO only used in composition
export const Counter = ({
    piid,
    width,
    amountAlignment,
    inlayText,
    restore
}: CounterProps) => {
    const dispatch = useAppDispatch()

    const amount = useAppSelector(selectArticleAmount(piid)) ?? 1

    const styles = {
        fontSize: "22px",
        lineHeight: "initial",
        paddingBottom: "3px"
    }

    const handleIncrement = () => {
        if (amount === 0) {
            restore()
            return
        }

        dispatch(incrementArticleAmount(piid))
    }

    const handleDecrement = () => {
        if (amount > 0) {
            dispatch(decrementArticleAmount(piid))
        }
    }

    const plusSymbol = "+"
    const minusSymbol = "-"

    return (
        <Stack direction="row" sx={{ zIndex: 1 }}>
            <CounterButton onClick={handleDecrement}>
                <Typography sx={styles}>{minusSymbol}</Typography>
            </CounterButton>
            <InputWithInlay
                piid={piid}
                inlayText={inlayText}
                amountAlignment={amountAlignment}
                width={width}
            />
            <CounterButton onClick={handleIncrement}>
                <Typography sx={styles}>{plusSymbol}</Typography>
            </CounterButton>
        </Stack>
    )
}
