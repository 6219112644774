import { Divider, Stack, Typography, useTheme } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { Toolbar } from "../Toolbar"
import {
    CompositionAccessoryGroup,
    CompositionProduct
} from "../composition.types"
import { getTotalArticleCount } from "../util"
import { Accessories } from "./Accessories"
import { Headline } from "./Headline"
import { SelectedProducts } from "./SelectedProducts"

interface ArticlePositionsProps {
    selectedProducts: Array<CompositionProduct>
    accessoryGroups: Array<CompositionAccessoryGroup>
}

export const ArticlePositions = ({
    selectedProducts = [],
    accessoryGroups = []
}: ArticlePositionsProps) => {
    const theme = useTheme()
    const { t } = useTranslation()

    const positions = t("common:articlePositions.positions", {
        count: getTotalArticleCount(selectedProducts, accessoryGroups)
    })

    return (
        // Id "articlePositions" is used to determine
        // scroll position for the sticky navigation
        // via the useScrollTarget() hook in StickyToolbar
        <Stack spacing={4} id="articlePositions" paddingBottom="1em">
            <Stack spacing={1.5}>
                <Stack
                    direction="row"
                    gap="0.6em"
                    sx={{
                        alignItems: "center"
                    }}
                >
                    <Headline
                        uppercase
                        text={t("common:articlePositions.yourComposition")}
                    />
                    <Typography
                        fontSize="12px"
                        color={theme.palette.text.secondary}
                    >
                        {positions}
                    </Typography>
                </Stack>

                <Divider
                    sx={{
                        borderColor: theme.palette.secondary.main
                    }}
                />
                <Toolbar />

                <SelectedProducts selectedProducts={selectedProducts} />
                <Accessories accessoryGroups={accessoryGroups} />
            </Stack>
        </Stack>
    )
}
