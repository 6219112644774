import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import {
    ElbridgeFinishRequest,
    ElbridgeFinishResponse
} from "./elbridgeConnectorAPI.types"

export const elbridgeConnectorAPI = createApi({
    reducerPath: "elbridgeConnectorAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/configurator/elbridge",
        credentials: "include"
    }),
    endpoints: (builder) => ({
        elbridgeConnectorFinish: builder.query<
            ElbridgeFinishResponse,
            ElbridgeFinishRequest
        >({
            query: (request) => {
                const { body } = request
                return {
                    method: "POST",
                    url: "/finish/",
                    body
                }
            }
        })
    })
})

export const { useLazyElbridgeConnectorFinishQuery } = elbridgeConnectorAPI
