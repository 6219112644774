import {
    Dropdown,
    getTheme,
    IDropdownOption,
    IDropdownStyles,
    ITheme
} from "@fluentui/react"
import React from "react"
import { useTranslation } from "react-i18next"

const getDropdownStyles = (theme: ITheme): Partial<IDropdownStyles> => ({
    root: {
        width: "100%"
    },
    title: {
        border: "1px solid " + theme.palette.neutralQuaternaryAlt,
        backgroundColor: theme.palette.white
    },

    dropdownItemsWrapper: {
        paddingLeft: "0.25rem"
    },

    subComponentStyles: {
        panel: {},
        label: {},
        multiSelectItem: {
            checkbox: {
                height: "16px",
                width: "16px",
                fontSize: "12px",
                paddingBottom: "3px"
            }
        }
    }
})

interface ArticleSorterProps {
    updateSorter: (direction: "asc" | "desc") => void
}

export const ArticleSorter = ({ updateSorter }: ArticleSorterProps) => {
    const theme = getTheme()
    const { t } = useTranslation()

    const dropdownOptions: IDropdownOption[] = [
        { key: "asc", text: t("common:ascending") },
        { key: "desc", text: t("common:descending") }
    ]

    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        item: IDropdownOption
    ): void => {
        updateSorter(item.key as "asc" & "desc")
    }

    return (
        <Dropdown
            placeholder={t("common:sort").toString()}
            onChange={(event, item) => onChange(event, item as IDropdownOption)}
            options={dropdownOptions}
            styles={getDropdownStyles(theme)}
        />
    )
}
