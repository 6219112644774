import { Stack } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"

import { CompositionAccessoryGroup } from "../composition.types"
import { Article } from "./Article"
import { ArticleAccordion } from "./ArticleAccordion"

interface AccessoriesProps {
    accessoryGroups: Array<CompositionAccessoryGroup>
}

export const Accessories = ({ accessoryGroups }: AccessoriesProps) => {
    const { t } = useTranslation()

    return (
        <Stack>
            {accessoryGroups.map(({ configurationTab, accessories }) => {
                const text = [
                    configurationTab,
                    t("common:articlePositions.accessory")
                ].join(" ")

                if (accessories.length === 0) {
                    return null
                }

                return (
                    <ArticleAccordion key={configurationTab} title={text}>
                        {accessories.map((accessory) => (
                            <Article
                                key={`${accessory.manufacturerId}|${accessory.manufacturerPid}`}
                                article={accessory}
                            />
                        ))}
                    </ArticleAccordion>
                )
            })}
        </Stack>
    )
}
