import { FolderOpenOutlined } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useState } from "react"

import { useLoadConfigurationCodeMutation } from "../../api/configurationCodesAPI"
import {
    selectConfigurationId,
    setConfigurationId,
    updateCuiHash
} from "../../app/configuratorSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { ConfiCodeLoadPopover } from "./ConfiCodeLoadPopover"

export const ConfiCodeLoadButton = () => {
    const loadableConfiCode = useAppSelector(
        (state) => state.conficode.loadableConfiCode
    )
    const dispatch = useAppDispatch()
    const theme = useTheme()
    const [popoverAnchor, setPopoverAnchor] =
        useState<HTMLButtonElement | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const [loadConfigurationCode] = useLoadConfigurationCodeMutation()
    const configurationId = useAppSelector(selectConfigurationId)

    const loadConfiCode = async (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setLoading(true)
        setError(false)
        setPopoverAnchor(event.currentTarget)

        if (loadableConfiCode && configurationId) {
            loadConfigurationCode({
                configurationId,
                configurationCodeId: loadableConfiCode
            }).then(
                (loadConfigurationResult) => {
                    if ("data" in loadConfigurationResult) {
                        const configurationId =
                            loadConfigurationResult.data.configurationStatus
                                .configurationId
                        dispatch(setConfigurationId(configurationId))
                        dispatch(updateCuiHash())
                        setLoading(false)
                    } else {
                        setError(true)
                        setLoading(false)
                    }
                },
                () => {
                    setError(true)
                    setLoading(false)
                }
            )
        } else {
            setError(true)
            setLoading(false)
        }
    }

    return (
        <>
            <IconButton
                sx={{
                    borderRadius: 0,
                    backgroundColor: theme.palette.primary.main
                }}
                size={"small"}
                disableRipple={true}
                onClick={loadConfiCode}
            >
                <FolderOpenOutlined color="secondary" />
            </IconButton>
            <ConfiCodeLoadPopover
                anchor={popoverAnchor}
                setAnchor={setPopoverAnchor}
                loading={loading}
                error={error}
            />
        </>
    )
}
