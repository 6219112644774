import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { ChangeEvent } from "react"

import { selectArticleAmount, setArticleAmount } from "../app/compositionSlice"
import { useAppDispatch, useAppSelector } from "../app/hooks"

const policy = {
    maxCount: 9999,
    minCount: 1
}

interface InputWithInlayProps {
    piid: string
    amountAlignment?: "start" | "center" | "end"
    width?: string
    inlayText?: string
}

// TODO only used in composition
export const InputWithInlay = (props: InputWithInlayProps) => {
    const {
        piid,
        amountAlignment = "start",
        width = "6.25rem",
        inlayText = ""
    } = props

    const dispatch = useAppDispatch()
    const articleAmount = useAppSelector(selectArticleAmount(piid)) ?? 1

    const theme = useTheme()
    const paddingXInPixel = 15
    const calcPaddingRight = (inlayText = "") => {
        return `${inlayText.length * 10 + paddingXInPixel}px`
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target

        if (value === "") {
            dispatch(setArticleAmount({ piid, amount: policy.minCount }))
            return
        }

        let parsed = Number.parseInt(value)
        if (isNaN(parsed) || parsed > policy.maxCount) {
            return
        }

        if (parsed < policy.minCount) {
            parsed = policy.minCount
        }

        dispatch(setArticleAmount({ piid, amount: parsed }))
    }

    return (
        <Box
            sx={{
                width,
                height: "2rem",
                display: "flex",
                alignItems: "center",
                position: "relative"
            }}
        >
            <span
                style={{
                    position: "absolute",
                    right: 10,
                    top: 4,
                    color: theme.palette.secondary.dark
                }}
            >
                {inlayText.toUpperCase()}
            </span>
            <input
                value={articleAmount}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "2rem",
                    fontSize: "14px",
                    textAlign: amountAlignment,
                    paddingTop: "3px",
                    paddingLeft: `${paddingXInPixel}px`,
                    paddingRight: calcPaddingRight(inlayText),
                    outline: "none",
                    border: "1px solid",
                    borderRadius: 0,
                    borderLeft: "none",
                    borderRight: "none",
                    borderColor: theme.palette.secondary.main
                }}
            />
        </Box>
    )
}
