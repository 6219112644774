import { ContainerTO } from "@encoway/c-services-js-client"

const SPLIT_LAYOUT = "SplitLayout"
const LEFT = "LEFT"
const RIGHT = "RIGHT"

export type LeftOrRight = typeof LEFT | typeof RIGHT

export const useSplitLayout = () => {
    const shouldDisplayLabel = (
        containerTO: ContainerTO,
        defaultCheck: (containerTO: ContainerTO) => boolean
    ) => {
        const { name } = containerTO
        if (name === LEFT || name === RIGHT) {
            return false
        }

        return defaultCheck(containerTO)
    }

    const isSplitLayoutContentBlock = (containerTO: ContainerTO) =>
        containerTO.name === SPLIT_LAYOUT

    const filterChildrenBySplitSide = (
        children: ContainerTO[],
        side: LeftOrRight
    ) => children.filter((child) => child.name === side)

    return {
        shouldDisplayLabel,
        isSplitLayoutContentBlock,
        filterChildrenBySplitSide
    }
}
