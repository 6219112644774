import { getTheme } from "@fluentui/react/lib/Styling"
import React from "react"

import {
    Dropdown,
    DropdownProps,
    IDropdownStyles
} from "@encoway/cui-configurator-components"

import { dropdownStyles } from "../dropdownStyles"

export const MyDropdown = (props: DropdownProps<IDropdownStyles>) => {
    const theme = getTheme()

    return (
        <Dropdown
            {...props}
            styles={{
                ...dropdownStyles(theme)
            }}
        />
    )
}
