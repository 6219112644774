import { getTheme, Stack } from "@fluentui/react"
import { CancelIcon, CheckMarkIcon } from "@fluentui/react-icons-mdl2"
import React from "react"

import {
    ArticleSearchFilter,
    SupplierFilterOptionsWithLogos
} from "../../../../../../../api/deepSearchAPI.types"
import { BrandCard } from "./BrandCard"
import { useSupplierFilter } from "./useSupplierFilter"

interface ChipProps {
    handleClick: () => void
}

const Chip = ({
    children,
    handleClick
}: React.PropsWithChildren<ChipProps>) => {
    const theme = getTheme()

    return (
        <Stack
            onClick={handleClick}
            style={{
                cursor: "pointer",
                padding: "5px 20px",
                borderRadius: 15,
                color: theme.palette.black,
                backgroundColor: theme.palette.neutralQuaternaryAlt
            }}
        >
            {children}
        </Stack>
    )
}

interface SupplierFilterProps {
    supplierData: SupplierFilterOptionsWithLogos
    updateFilter: (filter: ArticleSearchFilter) => void
}

export const SupplierFilter = ({
    supplierData,
    updateFilter
}: SupplierFilterProps) => {
    const { columnName, filterType } = supplierData
    const { supplierFilter, updateSupplierFilter, resetSupplierFilter } =
        useSupplierFilter()

    const handleSubmit = () => {
        updateFilter({
            columnName,
            filterType,
            value: supplierFilter
        })
    }

    const removeLastItem = (supplierName: string, splitToken: string) => {
        return supplierName.split(splitToken).slice(0, -1).join(splitToken)
    }

    return (
        <Stack tokens={{ childrenGap: 20 }}>
            <Stack
                wrap
                tokens={{ childrenGap: 20 }}
                horizontalAlign="start"
                horizontal
                style={{
                    marginTop: "3em"
                }}
            >
                {supplierData.filterValue.map(({ value, logo }) => (
                    <BrandCard
                        key={value}
                        name={removeLastItem(value, " ")}
                        logo={logo}
                        selected={supplierFilter.includes(value)}
                        onClick={() => updateSupplierFilter(value)}
                    />
                ))}
            </Stack>

            {supplierFilter.length > 0 && (
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Chip handleClick={handleSubmit}>
                        <CheckMarkIcon />
                    </Chip>
                    <Chip handleClick={resetSupplierFilter}>
                        <CancelIcon />
                    </Chip>
                </Stack>
            )}
        </Stack>
    )
}
