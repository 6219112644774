import { Stylesheet } from "@fluentui/react/lib/Styling"
import { ThemeProvider } from "@fluentui/react/lib/Theme"
import { Bus } from "baconjs"
import React from "react"

import { AppSwitch } from "@encoway/cui-application-components"

import {
    selectConfigurationId,
    selectCuiHash
} from "../../../app/configuratorSlice"
import { useAppSelector } from "../../../app/hooks"
import { useCuiTheme } from "../../../theme/themes.hooks"
import { useSettings } from "../configuration.hooks"
import { initComponents } from "./components/components"

initComponents()

Stylesheet.getInstance().setConfig({ namespace: "encoway" })

export const eventBus = new Bus()
export const CUI = () => {
    const cuiTheme = useCuiTheme()
    const settings = useSettings()

    // Used for clean re-render when a new configuration is loaded (same configuration id)
    // React re-renders a component when the key changes
    const cuiHash = useAppSelector(selectCuiHash)
    const configurationId = useAppSelector(selectConfigurationId)

    // we always start a configuration "outside" of the CUI (see ConfigurationSession.tsx). So do not render
    // if there is no configurationId present yet
    if (!settings || !configurationId) {
        return null
    }

    // Using the ThemeProvider and the AppSwitch directly is a workaround for PSU-4934
    return (
        <div key={cuiHash}>
            <ThemeProvider
                theme={cuiTheme}
                style={{ display: "flex", flex: 1, minWidth: 0 }}
            >
                <AppSwitch eventBus={eventBus} settings={settings} />
            </ThemeProvider>
        </div>
    )
}
