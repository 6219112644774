import { ITheme } from "@fluentui/react"

export const dropdownStyles = (theme: ITheme) => ({
    dropdown: {
        selectors: {
            ".ms-Dropdown-title > .ms-Stack": {
                color: theme.palette.black
            },
            ".ms-Dropdown-title .ms-Stack .ms-TooltipHost": {
                color: theme.palette.black
            }
        }
    },
    notReady: {
        selectors: {
            ".ms-Dropdown-title": {
                backgroundColor: theme.palette.white
            }
        }
    },
    option: {
        color: theme.palette.black,
        selectors: {
            ":hover": {
                color: theme.palette.themePrimary,
                backgroundColor: theme.palette.white
            },
            ":focus": {
                outline: "0"
            }
        }
    },
    optionSelected: {
        color: theme.palette.themePrimary
    },
    optionConflict: {
        color: theme.palette.black
    },
    detailOption: {
        selectors: {
            ":hover": {
                color: theme.palette.themePrimary,
                backgroundColor: theme.palette.white
            },
            ":focus": {
                outline: "0"
            }
        }
    },
    shortTextContainer: {
        selectors: {
            span: {
                color: theme.palette.black
            }
        }
    },
    shortTextNotSelected: {
        selectors: {
            span: {
                color: theme.palette.black
            }
        }
    }
})
