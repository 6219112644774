import { getTheme } from "@fluentui/react"
import React, { useEffect } from "react"

import { TabProps } from "@encoway/cui-configurator-components"

import { setSelectedTabId } from "../../../../../app/configuratorSlice"
import { useAppDispatch } from "../../../../../app/hooks"
import { MyTabOverride } from "./MyTab.override"

export const MyTab = (props: TabProps) => {
    const selectable = false
    const { selected, data } = props
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (selected) {
            dispatch(setSelectedTabId(data.id))
        }
    }, [selected])

    const theme = getTheme()
    const navColor = "#ECEFF1"
    const navHoverColor = "#d0d9dd"

    return (
        <MyTabOverride
            {...props}
            onClick={selectable ? props.onClick : () => null}
            styles={{
                tab: {
                    color: props.selected
                        ? theme.palette.white
                        : theme.palette.black,
                    backgroundColor: navColor,
                    width: "100%",
                    paddingLeft: "2.25em",
                    height: "36px",
                    position: "relative",
                    cursor: selectable ? "pointer" : "default",
                    "::before": {
                        position: "absolute",
                        zIndex: "1",
                        top: "5.5px",
                        left: "-13px",
                        width: "25px",
                        height: "25px",
                        content: "''",
                        display: "inline-block",
                        backgroundColor: theme.palette.white,
                        transform: "rotate(45deg)"
                    },
                    "::after": {
                        position: "absolute",
                        zIndex: "66",
                        top: "5.5px",
                        right: "-13px",
                        width: "25px",
                        height: "25px",
                        content: "''",
                        display: "inline-block",
                        backgroundColor: navColor,
                        transform: "rotate(45deg)"
                    },
                    ":hover, :hover::after, :active, :active::after": {
                        backgroundColor: selectable ? navHoverColor : navColor
                    }
                },
                notReady: {
                    color: theme.palette.black,
                    backgroundColor: navColor,
                    "::after": {
                        backgroundColor: navColor
                    },
                    ":hover, :hover::after, :active, :active::after": {
                        backgroundColor: selectable ? navHoverColor : navColor
                    }
                },
                selected: {
                    color: theme.palette.white,
                    backgroundColor: theme.palette.themePrimary,
                    "::after": {
                        backgroundColor: theme.palette.themePrimary
                    },
                    ":hover, :hover::after, :active, :active::after": {
                        color: theme.palette.white,
                        backgroundColor: theme.palette.themePrimary
                    }
                },
                text: {
                    fontWeight: "inherit",
                    display: "block", // otherwise maxWidth does not work

                    ...theme.fonts.large,
                    fontSize: "1em",
                    color: "inherit",
                    lineHeight: "2em"
                }
            }}
        />
    )
}
