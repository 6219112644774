import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import { useTheme } from "@mui/material/styles"
import * as React from "react"

interface ArticleAccordionProps {
    title: string
}

export const ArticleAccordion = ({
    title,
    children
}: React.PropsWithChildren<ArticleAccordionProps>) => {
    const theme = useTheme()
    const [expanded, setExpanded] = React.useState<boolean>(true)

    return (
        <div>
            <Accordion
                expanded={expanded}
                onChange={() => setExpanded((current) => !current)}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                        color={theme.palette.text.secondary}
                        fontWeight="bold"
                        fontSize="1.25rem"
                    >
                        {title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </div>
    )
}
