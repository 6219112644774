import { getTheme, Text } from "@fluentui/react"
import React from "react"

interface ArticleTitleProps {
    text: string
    uppercase?: boolean
}

export const ArticleTitle = ({
    text,
    uppercase = false
}: ArticleTitleProps) => {
    const theme = getTheme()

    return (
        <Text
            style={{
                fontSize: "18px",
                lineHeight: 1.2,
                fontWeight: "bold",
                letterSpacing: "-0.2px",
                color: theme.palette.neutralDark,
                ...(uppercase && { textTransform: "uppercase" })
            }}
        >
            {text}
        </Text>
    )
}
