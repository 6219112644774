import {
    ArticleSearchFilterOption,
    SupplierData
} from "../../../../../../api/deepSearchAPI.types"

export const getFilterOptionsWithoutSupplier = (
    filterOptions: Array<ArticleSearchFilterOption> = []
) => {
    return filterOptions.filter(
        (filter) => filter.columnName !== "herstellerName"
    )
}

export const getSupplierFilterOptionsWithImages = (
    filterOptions: Array<ArticleSearchFilterOption> = [],
    supplierData: Array<SupplierData> = []
) => {
    const [supplierFilterOptions] = getFilterOptionsOfSupplier(filterOptions)

    return {
        ...supplierFilterOptions,
        filterValue: injectImagesIntoSupplierFilterOptions(
            supplierData,
            supplierFilterOptions
        )
    }
}

const getFilterOptionsOfSupplier = (
    filterOptions: Array<ArticleSearchFilterOption> = []
) => {
    return filterOptions.filter(
        (filter) => filter.columnName === "herstellerName"
    )
}

// Map logos from supplierData into filterValues of filterOptionsSupplier
const injectImagesIntoSupplierFilterOptions = (
    supplierData: Array<SupplierData> = [],
    filterOptionsOfSupplier: ArticleSearchFilterOption = {} as ArticleSearchFilterOption
) => {
    const { filterValue = [] } = filterOptionsOfSupplier

    return filterValue.map((filterValueEntry) => {
        const supplierEntry = supplierData.find(
            (supplierEntry) => supplierEntry.name === filterValueEntry.value
        )

        return {
            ...filterValueEntry,
            logo: supplierEntry?.logo ?? ""
        }
    })
}
