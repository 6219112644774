import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import UserService from "../services/userService"
import { LoadProductCompositionResponse } from "./compositionAPI.types"

export const compositionAPI = createApi({
    reducerPath: "compositionAPI",
    baseQuery: fetchBaseQuery({
        baseUrl:
            "/api/configuration/configurations/deepsearch/productcomposition",
        prepareHeaders: (headers: Headers) => {
            headers.set(
                "Authorization",
                `Bearer ${UserService.getAccessTokenFromStorage()}`
            )
        },
        credentials: "include"
    }),
    endpoints: (builder) => ({
        loadComposition: builder.query<LoadProductCompositionResponse, string>({
            query: (configurationId) => {
                return {
                    method: "GET",
                    url: `/${configurationId}/load`
                }
            }
        })
    })
})

export const { useLazyLoadCompositionQuery } = compositionAPI
