import { Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"

interface InfoTextProps {
    text: string
}

export const InfoText = ({ text }: InfoTextProps) => {
    const theme = useTheme()
    return (
        <Typography fontSize="10px" color={theme.palette.secondary.dark}>
            {text}
        </Typography>
    )
}
