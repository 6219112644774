import { FrontendThemeDefinition } from "./themes.types"

const typography = [
    "Gill Sans Std",
    "-apple-system",
    "BlinkMacSystemFont",
    "Segoe UI",
    "Roboto",
    "Helvetica Neue",
    "Arial",
    "Noto Sans",
    "sans-serif"
]

const FONT_FAMILY = typography.join(",")

export const theme: FrontendThemeDefinition = {
    muiThemeDefinition: {
        palette: {
            primary: {
                main: "#006ab3",
                light: "#3387C2",
                dark: "#004A7D"
            },
            text: {
                primary: "#006ab3",
                secondary: "#202020",
                disabled: "#d1d9dd"
            },
            secondary: {
                main: "#eceff1",
                light: "#EFF2F3"
            }
        },
        typography: {
            fontFamily: FONT_FAMILY
        }
    },
    cuiThemeDefinition: {
        defaultFontStyle: {
            fontFamily: FONT_FAMILY
        },
        palette: {
            themePrimary: "#006ab3",
            themeLighterAlt: "#f2f8fc",
            themeLighter: "#cce3f3",
            themeLight: "#a2cce8",
            themeTertiary: "#549fd1",
            themeSecondary: "#177abc",
            themeDarkAlt: "#0060a1",
            themeDark: "#005188",
            themeDarker: "#003c64",
            neutralLighterAlt: "#faf9f8",
            neutralLighter: "#f3f2f1",
            neutralLight: "#edebe9",
            neutralQuaternaryAlt: "#e1dfdd",
            neutralQuaternary: "#d0d0d0",
            neutralTertiaryAlt: "#c8c6c4",
            neutralTertiary: "#595959",
            neutralSecondary: "#373737",
            neutralPrimaryAlt: "#2f2f2f",
            neutralPrimary: "#000000",
            neutralDark: "#151515",
            black: "#0b0b0b",
            white: "#ffffff"
        }
    },
    customColors: {
        white: "#FFFFFF",
        buttonDarkerGray: "#d2d9dd",
        grayButtonBgHover: "#a5a7a8",
        lightGray: "#ECECEC",
        mediumGray: "#636363",
        darkGray: "#737373",
        lightGreen: "#D8FFEE",
        darkGreen: "#00A97A",
        almostBlack: "#202020",
        tealLight: "#78909c",
        tealDark: "#4b626d",
        productGroups: {
            selected: "#00a97a14",
            selectedHovered: "#00a97a1f",
            hovered: "#0000000a"
        },
        lightBlue: "#d1d9dd",
        mediumLightBlue: "#a4b0b9",
        headerPopoverBackground: "#13a2b7"
    }
}
