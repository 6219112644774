import { ArticleAmounts } from "../../../app/compositionSlice"
import {
    CompositionAccessoryGroup,
    CompositionProduct
} from "../composition.types"

export const getTotalPrice = (
    selectedProducts: Array<CompositionProduct>,
    accessoryGroups: Array<CompositionAccessoryGroup>,
    articleAmounts: ArticleAmounts
) => {
    // Accumulator function
    const accumulatePrice = (acc: number, product: CompositionProduct) => {
        const price = Number(product.price)

        return (
            acc +
            price *
                articleAmounts[
                    `${product.manufacturerId}|${product.manufacturerPid}`
                ]
        )
    }

    // Accumulate all product prices
    const subTotalProducts = selectedProducts.reduce(accumulatePrice, 0)

    // Accumulate all accessory prices
    const subTotalAccessories = accessoryGroups
        .map((group) => group.accessories)
        .flat(1)
        .reduce(accumulatePrice, 0)

    return subTotalProducts + subTotalAccessories
}
