import { getTheme } from "@fluentui/react"
import React from "react"

import {
    FilterDropdown,
    IFilterDropdownProps
} from "@encoway/cui-configurator-components"

import { dropdownStyles } from "../dropdownStyles"

export function MyFilterDropdown(props: IFilterDropdownProps) {
    const theme = getTheme()

    return (
        <FilterDropdown
            {...props}
            styles={{
                ...dropdownStyles(theme)
            }}
        />
    )
}
