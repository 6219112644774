import { Box, Container, Stack, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import { useScrollTargetReached } from "../../common/useScrollTargetReached"
import { useCustomColors } from "../../theme/themes.hooks"
import { Toolbar } from "./Toolbar"

interface StickyToolbarProps {
    total: number
}

export const StickyToolbar = ({ total }: StickyToolbarProps) => {
    const theme = useTheme()
    const customColors = useCustomColors()
    const { t } = useTranslation()

    const stickyToolbarRef = useRef<HTMLDivElement>()

    const targetReached = useScrollTargetReached(
        "articlePositions", // Id defined in ArticlePositions.tsx
        stickyToolbarRef.current?.offsetHeight || 0
    )

    // Gesamt 4.640,25 €
    const composedTotal = t("common:total", { total })

    return (
        <Box
            id="stickyToolBar"
            ref={stickyToolbarRef}
            sx={{
                padding: "0.75em",
                borderTop: "1px solid",
                backgroundColor: customColors.white,
                borderTopColor: theme.palette.secondary.main,
                position: "sticky",
                bottom: 0,
                zIndex: 999,
                ...(targetReached && {
                    position: "relative"
                })
            }}
        >
            <Container>
                <Stack alignItems="end" gap="0.75em">
                    <Typography fontWeight="bold">{composedTotal}</Typography>
                    <Toolbar />
                </Stack>
            </Container>
        </Box>
    )
}
