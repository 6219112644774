import {
    CompositionAccessoryGroup,
    CompositionProduct
} from "../composition.types"

export const getArticleIds = (
    selectedProducts: Array<CompositionProduct> = [],
    accessoryGroups: Array<CompositionAccessoryGroup> = []
) => {
    const productIds = selectedProducts.map(
        (compositionProduct) =>
            `${compositionProduct.manufacturerId}|${compositionProduct.manufacturerPid}`
    )
    const accessoryIds = accessoryGroups
        .map((group) => group.accessories)
        .flat(1)
        .map(
            (compositionProduct) =>
                `${compositionProduct.manufacturerId}|${compositionProduct.manufacturerPid}`
        )

    return [...productIds, ...accessoryIds]
}
