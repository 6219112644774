import {
    IButtonStyles,
    IIconProps,
    Label,
    PrimaryButton,
    Spinner,
    Stack
} from "@fluentui/react"
import React from "react"
import { useTranslation } from "react-i18next"

interface ChooseButtonProps {
    onClick: () => void
    isLoading: boolean
}

const chooseButton: IButtonStyles = {
    root: {
        padding: "1em",
        borderRadius: 0
    },
    flexContainer: {
        flexDirection: "row-reverse",
        justifyContent: "space-between"
    }
}

const iconProps: IIconProps = {
    iconName: "ChevronRight",
    styles: {
        root: {
            "& > span": {
                paddingBottom: "3px"
            }
        }
    }
}

export const ChooseButton = ({ onClick, isLoading }: ChooseButtonProps) => {
    const { t } = useTranslation()

    return (
        <Stack>
            {!isLoading && (
                <PrimaryButton
                    onClick={onClick}
                    styles={chooseButton}
                    iconProps={iconProps}
                    text={t("common:choose").toString()}
                />
            )}
            {isLoading && (
                <Stack
                    horizontal
                    horizontalAlign="center"
                    verticalAlign="center"
                    tokens={{ childrenGap: 10 }}
                >
                    <Spinner />
                    <Label>{t("common:selectProduct")}</Label>
                </Stack>
            )}
        </Stack>
    )
}
