import { getTheme, Label, Spinner, Stack } from "@fluentui/react"
import { ErrorIcon } from "@fluentui/react-icons-mdl2"
import { DefaultButton } from "@fluentui/react/lib/Button"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { ParameterTO } from "@encoway/c-services-js-client"

import { useLazyLoadProductSelectionQuery } from "../../../../../../api/deepSearchAPI"
import { Paging, Sorter } from "../../../../../../api/deepSearchAPI.types"
import { InfoLayout } from "../../../../../../common/InfoLayout"
import { NoArticlesFound } from "./NoArticlesFound"
import { ProductSelectionContext } from "./ProductSelectionContext"
import { ArticleFilter } from "./articles/ArticleFilter"
import { ArticleList } from "./articles/ArticleList"
import {
    getFilterOptionsWithoutSupplier,
    getSupplierFilterOptionsWithImages
} from "./filterOptions"
import { ArticleSorter } from "./filters/ArticleSorter"
import { SupplierFilter } from "./filters/SupplierFilter"
import { useFilters } from "./filters/useFilters"
import { getSubConfigurationId } from "./selection"

type Props = {
    data: ParameterTO
}

export const ProductSelection = ({ data }: Props) => {
    const theme = getTheme()
    const { t } = useTranslation()

    const [loadProductSelection, response] = useLazyLoadProductSelectionQuery()
    const { filterList, updateFilter, resetFilters } = useFilters()

    const [paging] = useState<Paging>({
        page: 1,
        pageSize: 50
    })

    const [sorter, setSorter] = useState<Sorter>({
        sortColumn: "herstellerName",
        sortOrder: "asc"
    })

    const subConfigurationId = getSubConfigurationId(data)

    useEffect(() => {
        if (subConfigurationId) {
            loadProductSelection({
                configurationId: subConfigurationId,
                body: {
                    ...paging,
                    ...sorter,
                    filter: filterList
                }
            })
        }
    }, [subConfigurationId, filterList, sorter])

    const productSelectionContext = useMemo(
        () => ({ subConfigurationId, parameterTO: data }),
        [subConfigurationId, data]
    )

    // only render if we have a sub configuration. Otherwise, we might get a loading spinner...
    if (!subConfigurationId) {
        return null
    }

    const updateSorter = (direction: "asc" | "desc") => {
        setSorter({
            sortColumn: "herstellerName",
            sortOrder: direction
        })
    }

    if (response.isLoading || response.isFetching || response.isUninitialized) {
        return (
            <InfoLayout>
                <Spinner />
                <Label>{t("common:loadingProducts")}</Label>
            </InfoLayout>
        )
    }

    if (response.isError) {
        return (
            <InfoLayout>
                <ErrorIcon style={{ fontSize: "1.25rem" }} />
                <Label>{t("common:loadingProductsError")}</Label>
            </InfoLayout>
        )
    }

    const { products, filterOptions, supplierData, hitCount } = response.data
    if (hitCount === 0) {
        return <NoArticlesFound />
    }

    const filterOptionsWithoutSupplier =
        getFilterOptionsWithoutSupplier(filterOptions)
    const supplierFilterOptionsWithImages = getSupplierFilterOptionsWithImages(
        filterOptions,
        supplierData
    )

    return (
        <ProductSelectionContext.Provider value={productSelectionContext}>
            <Stack
                style={{
                    gap: "5em",
                    marginBottom: "5.5em"
                }}
            >
                <SupplierFilter
                    supplierData={supplierFilterOptionsWithImages}
                    updateFilter={updateFilter}
                />

                <Stack
                    style={{
                        gap: "10px"
                    }}
                >
                    <Stack
                        horizontal
                        style={{
                            gap: "10px"
                        }}
                    >
                        {filterOptionsWithoutSupplier.map((filter) => {
                            return (
                                <ArticleFilter
                                    key={filter.columnName}
                                    filter={filter}
                                    updateFilter={updateFilter}
                                />
                            )
                        })}

                        <ArticleSorter updateSorter={updateSorter} />
                    </Stack>
                    <DefaultButton
                        onClick={resetFilters}
                        style={{
                            border: 0,
                            width: 200,
                            color: theme.palette.white,
                            backgroundColor: theme.palette.themePrimary
                        }}
                    >
                        {t("common:resetFilters")}
                    </DefaultButton>
                </Stack>

                <ArticleList articles={products} hitCount={hitCount} />
            </Stack>
        </ProductSelectionContext.Provider>
    )
}
