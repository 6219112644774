import { SxProps, Typography } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"

interface HeadlineProps {
    text: string
    uppercase?: boolean
    sx?: SxProps
}

export const Headline = ({
    text,
    uppercase = false,
    sx = {}
}: HeadlineProps) => {
    const theme = useTheme()
    let styles: SxProps = {
        fontWeight: "bold",
        letterSpacing: "-0.2px",
        color: theme.palette.text.secondary
    }

    if (uppercase) {
        styles = {
            ...styles,
            textTransform: "uppercase",
            ...sx
        }
    }

    return (
        <Typography variant="h5" sx={styles}>
            {text}
        </Typography>
    )
}
