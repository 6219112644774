import * as React from "react"

import { Tabs, TabsProps } from "@encoway/cui-configurator-components"

export function MyTabs(props: TabsProps) {
    return (
        <Tabs
            {...props}
            styles={{
                content: {
                    overflow: "auto"
                },
                tabsBar: {
                    height: "auto",
                    padding: "0.5em 2em",
                    "> div": {
                        columnGap: "4px",
                        alignItems: "center"
                    },
                    "> div > div": {
                        flex: "1 1 0%"
                    },
                    "*:first-of-type>button": {
                        paddingLeft: "1em"
                    },
                    "*:first-of-type>button::before": {
                        content: "none"
                    },
                    "*:last-of-type>button::after": {
                        content: "none"
                    }
                }
            }}
        />
    )
}
