import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import commonEN from "../locales/de/common.json"
import glossaryEN from "../locales/de/glossary.json"

export const initI18n = () => {
    const instance = i18n.use(initReactI18next)
    return instance.init({
        lng: "de-DE",
        debug: true,
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        resources: {
            "de-DE": {
                common: commonEN,
                glossary: glossaryEN
            }
        }
    })
}
