import { createContext } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"

type ProductSelection = {
    subConfigurationId: string | null
    parameterTO: ParameterTO | null
}

// holds the id of the sub configuration and the parameter which has the "productselection" viewport
export const ProductSelectionContext = createContext<ProductSelection>({
    subConfigurationId: null,
    parameterTO: null
})
