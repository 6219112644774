import { getTheme } from "@fluentui/react/lib/Styling"
import React from "react"

import { ParameterTO } from "@encoway/c-services-js-client"

import { useValueToPresent } from "./MyValuePresenter.hooks"

export const MyValuePresenter = ({ data }: { data: ParameterTO }) => {
    const theme = getTheme()
    const valueToDisplay = useValueToPresent(data)

    return (
        <div
            style={{
                marginTop: "3em",
                display: "block",
                backgroundColor: theme.palette?.neutralLighter,
                textAlign: "center",
                paddingTop: "0.75em",
                paddingBottom: "3em",
                whiteSpace: "nowrap",
                fontWeight: 700
            }}
        >
            <div
                style={{
                    color: theme.palette?.themePrimary,
                    paddingBottom: "1em"
                }}
            >
                <span>{data.translatedName}:</span>
            </div>
            <div style={{ fontSize: "2.5em", color: theme.palette?.black }}>
                <span>{valueToDisplay}</span>
            </div>
        </div>
    )
}
