import React, { ReactNode } from "react"

import { useConfigurationSession } from "./configuration.hooks"

type Props = {
    children: ReactNode
}

export const ConfigurationSession = (props: Props) => {
    useConfigurationSession()
    return <>{props.children}</>
}
