import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import UserService from "../services/userService"
import {
    LoadProductSelectionRequest,
    LoadProductSelectionResponse,
    SelectProductRequest
} from "./deepSearchAPI.types"

export const deepSearchAPI = createApi({
    reducerPath: "deepSearchAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/configuration/configurations/deepsearch",
        prepareHeaders: (headers: Headers) => {
            headers.set(
                "Authorization",
                `Bearer ${UserService.getAccessTokenFromStorage()}`
            )
        },
        credentials: "include"
    }),
    endpoints: (builder) => ({
        loadProductSelection: builder.query<
            LoadProductSelectionResponse,
            LoadProductSelectionRequest
        >({
            query: (queryArg) => {
                const { configurationId, body } = queryArg
                return {
                    method: "POST",
                    url: `/productselection/${configurationId}/load`,
                    body
                }
            }
        }),
        selectProduct: builder.mutation<void, SelectProductRequest>({
            query: (queryArg) => {
                const { configurationId, body } = queryArg
                return {
                    method: "POST",
                    url: `/productselection/${configurationId}/select`,
                    body
                }
            }
        })
    })
})

export const { useLazyLoadProductSelectionQuery, useSelectProductMutation } =
    deepSearchAPI
