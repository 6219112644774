import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Menu, MenuItem, SxProps } from "@mui/material"
import { MenuItemProps } from "@mui/material/MenuItem/MenuItem"
import { useTheme } from "@mui/material/styles"
import React from "react"
import { useTranslation } from "react-i18next"

import { SecondaryButton } from "../../../common"

interface DropDownMenuItemProps extends MenuItemProps {
    text: string
}

const DropDownMenuItem = (props: DropDownMenuItemProps) => {
    return (
        <MenuItem {...props} disableRipple disableTouchRipple>
            {props.text}
        </MenuItem>
    )
}

interface DropDownButtonProps {
    text: string
    sx: SxProps
}

export const DropDownButton = ({ text, sx }: DropDownButtonProps) => {
    const theme = useTheme()
    const [anchorElement, setAnchorElement] =
        React.useState<null | HTMLElement>(null)

    const isOpen = Boolean(anchorElement)

    const { t } = useTranslation()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorElement(null)
    }

    const menuEntries = [
        { id: "replace", label: t("common:articlePositions.replaceArticle") },
        { id: "delete", label: t("common:articlePositions.deleteArticle") }
    ]

    return (
        <>
            <SecondaryButton
                disabled
                id="dropDownButton"
                aria-controls={isOpen ? "dropdownMenu" : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? "true" : undefined}
                onClick={handleClick}
                sx={{
                    paddingRight: 0,
                    width: "7.5rem",
                    justifyContent: "space-between",
                    ...sx
                }}
            >
                {text}
                {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </SecondaryButton>
            <Menu
                id="dropdownMenu"
                anchorEl={anchorElement}
                open={isOpen}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "dropDownButton"
                }}
                sx={{
                    color: theme.palette.text.secondary,
                    "& .MuiPaper-root": {
                        borderRadius: 0,
                        border: "1px solid",
                        color: theme.palette.text.secondary,
                        borderColor: theme.palette.secondary.main
                    }
                }}
            >
                {menuEntries.map(({ label, id }) => {
                    return (
                        <DropDownMenuItem
                            key={id}
                            text={label}
                            onClick={handleClose}
                            dense
                        />
                    )
                })}
            </Menu>
        </>
    )
}
