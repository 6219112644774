import { Stack } from "@mui/material"
import React from "react"

import { SendButton } from "./SendButton"

export const Toolbar = () => {
    return (
        <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <SendButton />
        </Stack>
    )
}
