import { Http } from "@encoway/c-services-js-client"

import AuthenticationClient from "./authenticationClient"
import { TokenResponse } from "./authenticationClient.types"

const REFRESH_OFFSET_MS = 30 * 1000
const STORAGE_ACCESS_TOKEN = "configuratorAccessToken"
const STORAGE_REFRESH_TOKEN = "configuratorRefreshToken"
let refreshIntervalId: number

/**
 * Initialize the login of the user on the Authorization-Server
 */
const init = async () => {
    // Check if the user is redirect from Authorization-Server
    const sessionState = AuthenticationClient.getAuthServerState()

    const url = new URL(window.location.href)
    const autoLoginToken = url.searchParams.get("autoTkn")

    const validTokensInStorage = (): boolean => {
        const accessToken = getAccessTokenFromStorage()
        const refreshToken = getRefreshTokenFromStorage()

        return !!(
            accessToken &&
            refreshToken &&
            accessToken !== "" &&
            refreshToken !== ""
        )
    }

    // Auto login - override always
    if (autoLoginToken) {
        await handleAutoLoginToken(autoLoginToken)
        return
    }

    // Already logged in
    if (validTokensInStorage()) {
        return // do nothing
    }

    // Manual KeyCloak login
    if (!sessionState) {
        // Init login on CPQ-Backend and redirect user for login to Authorization-Server
        const props = await AuthenticationClient.getAuthenticationProperties()
        AuthenticationClient.redirectToAuthorizationServer(props)
    } else {
        /*
         * The user is coming back from Authorization-Server after a successful login.
         * Request and store the AccessToken
         */
        const token = await AuthenticationClient.getAccessToken()
        tokenize(token)
    }
}

// CUSTOM
const handleAutoLoginToken = async (autoLoginToken: string) => {
    const response = await Http.Plain().json(
        "/api/configuration/autologin/?token=" + autoLoginToken
    )
    saveAccessTokenInStorage(response.access_token)
    saveRefreshTokenInStorage(response.refresh_token)
    initRefreshTokenHandler(3 * 60 * 1000)
}

const tokenize = (token: TokenResponse | null) => {
    if (token) {
        saveAccessTokenInStorage(token.access_token.token_value)
        saveRefreshTokenInStorage(token.refresh_token.token_value)
        initRefreshTokenHandler(calculateRefreshIntervalInMS(token))
    } else {
        console.error("Login failed. If have no AccessToken")
    }
}

// STORAGE USAGE FOR ACCESS & REFRESH TOKEN
const saveAccessTokenInStorage = (accessToken: string) =>
    sessionStorage.setItem(STORAGE_ACCESS_TOKEN, accessToken)
const getAccessTokenFromStorage = () =>
    sessionStorage.getItem(STORAGE_ACCESS_TOKEN) ?? ""
const saveRefreshTokenInStorage = (refreshToken: string) =>
    sessionStorage.setItem(STORAGE_REFRESH_TOKEN, refreshToken)
const getRefreshTokenFromStorage = () =>
    sessionStorage.getItem(STORAGE_REFRESH_TOKEN) ?? ""

/**
 * Requests a new access token to replace the current one.
 */
const refreshToken = async () => {
    const oldRefreshToken = getRefreshTokenFromStorage()
    if (oldRefreshToken) {
        const token = await AuthenticationClient.refreshToken(oldRefreshToken)
        saveAccessTokenInStorage(token.access_token.token_value)
        saveRefreshTokenInStorage(token.refresh_token.token_value)
    }
}

/**
 * Initializes a handler to refresh the access token automatically.
 * @param intervalInMS Refresh interval in milliseconds
 */
const initRefreshTokenHandler = (intervalInMS: number) => {
    refreshIntervalId = window.setInterval(refreshToken, intervalInMS)
}

/**
 * Removed the handler to refresh the access token.
 */
const removeRefreshTokenHandler = () => {
    refreshIntervalId && window.clearInterval(refreshIntervalId)
}

/**
 * Calculate to refresh interval based on the token expire date
 * We use a 30sec margin to refresh the token not to edgy
 */
const calculateRefreshIntervalInMS = (token: TokenResponse) => {
    const tokenExpires = token.access_token.expires_at
    const now = new Date().getTime()
    // This will produce a date from 1970, but with the interval in ms we need to refresh the token
    return new Date(tokenExpires - now - REFRESH_OFFSET_MS).getTime()
}

/**
 * Handler to end a user-session on the Authorization-Server and to clear
 * all local authorization information.
 */
const logout = async (): Promise<void> => {
    removeRefreshTokenHandler()
    sessionStorage.removeItem(STORAGE_ACCESS_TOKEN)
    sessionStorage.removeItem(STORAGE_REFRESH_TOKEN)
    await AuthenticationClient.doLogout()
}

const UserService = {
    init,
    getAccessTokenFromStorage,
    logout
}

export default UserService
