import { Spinner } from "@fluentui/react"
import { ErrorIcon } from "@fluentui/react-icons-mdl2"
import React, { MouseEventHandler, useRef } from "react"
import { useTranslation } from "react-i18next"

import { useLazyElbridgeConnectorFinishQuery } from "../../api/elbridgeConnectorAPI"
import { ElbridgeFinishRequestArticle } from "../../api/elbridgeConnectorAPI.types"
import { selectArticleAmounts } from "../../app/compositionSlice"
import { selectElbridgeId } from "../../app/configuratorSlice"
import { useAppSelector } from "../../app/hooks"
import { PrimaryButton } from "../../common"

export const SendButton = () => {
    const formRef = useRef<HTMLFormElement | null>(null)
    const { t } = useTranslation()
    const elbridgeId = useAppSelector(selectElbridgeId)
    const articleAmounts = useAppSelector(selectArticleAmounts())
    const [elbridgeConnectorFinish, elbridgeConnectorFinishResponse] =
        useLazyElbridgeConnectorFinishQuery()

    if (!elbridgeId) {
        // We currently only support sending the BOM via ELBRIDGE.
        // No ELBRIDGE = No Send-Button
        return null
    }

    const onClick: MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault()
        const articles = Object.keys(articleAmounts).map(
            (gtin): ElbridgeFinishRequestArticle => {
                return {
                    MANUFACTURER_PID: gtin,
                    QUANTITY: String(articleAmounts[gtin])
                }
            }
        )

        elbridgeConnectorFinish({
            body: {
                ELBRIDGE_ID: elbridgeId,
                LOCALE: "de-DE",
                ARTICLES: articles
            }
        }).then(() => formRef.current?.submit())
    }

    return (
        <>
            <PrimaryButton onClick={onClick}>
                {(elbridgeConnectorFinishResponse.isLoading ||
                    elbridgeConnectorFinishResponse.isFetching) && <Spinner />}
                {elbridgeConnectorFinishResponse.isError && <ErrorIcon />}
                {t("common:articlePositions.toolbar.sendToSAP")}
            </PrimaryButton>
            {elbridgeConnectorFinishResponse.data && (
                <form
                    action={elbridgeConnectorFinishResponse.data.hookUrl}
                    method="post"
                    encType="multipart/form-data"
                    ref={formRef}
                    style={{ display: "none" }}
                >
                    <input
                        type="hidden"
                        name="version"
                        value={elbridgeConnectorFinishResponse.data.version}
                    />
                    <input
                        type="hidden"
                        name="result"
                        value={elbridgeConnectorFinishResponse.data.result}
                    />
                    <input type="submit" value="Send" />
                </form>
            )}
        </>
    )
}
