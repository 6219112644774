import { getTheme, Image, ImageFit, ITheme, Stack } from "@fluentui/react"
import { mergeStyles } from "@fluentui/react/lib/Styling"
import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"

import { Constants } from "@encoway/react-configurator"

import { ProductSelectionProduct } from "../../../../../../../api/deepSearchAPI.types"
import { eventBus } from "../../../../CUI"
import { useProductSelection } from "../useProductSelection"
import { ArticleInfo } from "./ArticleInfo"
import { ArticlePricePerUnit } from "./ArticlePricePerUnit"
import { ArticleSelectionError } from "./ArticleSelectionError"
import { ChooseButton } from "./ChooseButton"

const stackStyles = (theme: ITheme) =>
    mergeStyles({
        minHeight: "150px !important",
        padding: "1em",
        border: "1px solid",
        borderColor: theme.palette.neutralQuaternaryAlt,
        "&.isSelected": {
            border: "3px solid",
            borderColor: theme.palette.themePrimary
        }
    })

interface ArticleProps {
    article: ProductSelectionProduct
}

export const Article = ({ article }: ArticleProps) => {
    const {
        manufacturerId,
        manufacturerPid,
        articleName,
        image,
        price,
        supplierName
    } = article

    const piid = `${manufacturerId}|${manufacturerPid}`

    const theme = getTheme()
    const { t } = useTranslation()
    const { selectProduct, isProductSelected, isError, isLoading } =
        useProductSelection(piid, supplierName)

    // "Art.-Nr. 1234566"
    const composedArticleNumber = t("common:articlePositions.articleNumber", {
        articleNumber: manufacturerPid
    })

    const parsedPrice = Number(price)

    return (
        <>
            <Stack
                horizontal
                horizontalAlign="space-between"
                className={classNames(stackStyles(theme), {
                    isSelected: isProductSelected
                })}
            >
                <Image
                    src={image}
                    width={100}
                    imageFit={ImageFit.centerCover}
                />

                <ArticleInfo
                    articleNumber={composedArticleNumber}
                    articleName={articleName}
                />

                <ArticlePricePerUnit
                    preTax={parsedPrice}
                    postTax={parsedPrice}
                    unitSize={1}
                    unitDescriptor={"St"}
                />

                <ChooseButton
                    isLoading={isLoading}
                    onClick={() => {
                        selectProduct().then(() => {
                            // selecting a product might add new tabs. We have to reload the UI, otherwise they don't appear.
                            eventBus.push(Constants.Events.reloadState)
                        })
                    }}
                />
            </Stack>
            {isError && <ArticleSelectionError />}
        </>
    )
}
