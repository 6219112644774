import { MessageBar, MessageBarType } from "@fluentui/react"
import React from "react"
import { useTranslation } from "react-i18next"

export const ArticleSelectionError = () => {
    const { t } = useTranslation()
    return (
        <MessageBar
            messageBarType={MessageBarType.error}
            isMultiline={true}
            dismissButtonAriaLabel="Close"
        >
            {t("common:chooseProductSelectionError")}
        </MessageBar>
    )
}
