import React from "react"

// Framework agnostic implementation to be used
// in ProductSelection (FluentUI)
// and ProductComposition (MUI)
export const InfoLayout = ({ children }: React.PropsWithChildren) => {
    return (
        <div
            style={{
                gap: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5rem"
            }}
        >
            {children}
        </div>
    )
}
