import { Box, Container, Grid } from "@mui/material"
import React from "react"

import { useCustomColors } from "../../theme/themes.hooks"
import { ConfiCodePanel } from "./ConfiCodePanel"
import { ConfiCodeSaveButton } from "./ConfiCodeSaveButton"
import { HeaderTitle } from "./HeaderTitle"

export const Header = () => {
    const customColors = useCustomColors()

    return (
        <>
            <Box
                sx={{
                    backgroundColor: customColors.tealLight
                }}
            >
                <Container
                    maxWidth={false}
                    sx={{
                        maxWidth: "1600px",
                        padding: "0em 52px !important"
                    }}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={9.5}
                            sx={{
                                display: "inline-flex",
                                alignItems: "center"
                            }}
                        >
                            <HeaderTitle />
                        </Grid>
                        <ConfiCodePanel />
                    </Grid>
                </Container>
            </Box>
            <Container
                sx={{
                    maxWidth: "1600px",
                    padding: "0.5em 52px !important",
                    display: "flex",
                    justifyContent: "flex-end"
                }}
                maxWidth={false}
            >
                <ConfiCodeSaveButton />
            </Container>
        </>
    )
}
