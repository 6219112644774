import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type ConfiCodeState = {
    loadableConfiCode?: string
}

export const createInitialConfiCodeState = (): ConfiCodeState => ({})

const confiCode = createSlice({
    name: "conficode",
    initialState: createInitialConfiCodeState(),
    reducers: {
        setLoadableConfiCode: (state, action: PayloadAction<string>) => {
            state.loadableConfiCode = action.payload
        }
    }
})

export const { setLoadableConfiCode } = confiCode.actions
export default confiCode.reducer
