import { Stack } from "@fluentui/react"
import React from "react"

import { ArticleTitle } from "./ArticleTitle"
import { InfoText } from "./InfoText"

interface ArticleInfoProps {
    articleNumber: string
    articleName: string
}

export const ArticleInfo = ({
    articleNumber,
    articleName
}: ArticleInfoProps) => {
    return (
        <Stack
            style={{
                width: "300px",
                rowGap: "10px"
            }}
        >
            <InfoText text={articleNumber} />
            <ArticleTitle text={articleName} />
        </Stack>
    )
}
