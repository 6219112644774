import { TextField } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"
import { useTranslation } from "react-i18next"

import { setLoadableConfiCode } from "../../app/conficodeSlice"
import { useAppDispatch } from "../../app/hooks"
import { useCuiPalette } from "../../theme/themes.hooks"

export const ConfiCodeInput = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const theme = useTheme()
    const cuiPalette =
        useCuiPalette() /* apparently we now use cui colors in mui */

    return (
        <TextField
            id="outlined-basic"
            variant="outlined"
            size="small"
            onChange={(event) =>
                dispatch(setLoadableConfiCode(event.target.value))
            }
            InputLabelProps={{
                sx: {
                    "&input": {
                        color: cuiPalette?.neutralLight
                    }
                }
            }}
            placeholder={
                t("glossary:header.loadConfigurationPlaceholder") ?? ""
            }
            sx={{
                input: {
                    color: "black",
                    backgroundColor: "white",
                    fontSize: ".75em",
                    "::placeholder": {
                        color: cuiPalette?.neutralTertiary
                    }
                },
                ".MuiInputBase-root": {
                    borderRadius: 0
                },
                padding: 0,
                "&:hover": {
                    borderColor: theme.palette.primary.main,
                    transition: "0.3s"
                },
                width: "90%"
            }}
        />
    )
}
