import { ButtonProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"

import { PrimaryButton } from "./PrimaryButton"

export const SecondaryButton = (props: ButtonProps) => {
    const theme = useTheme()
    return (
        <PrimaryButton
            {...props}
            sx={{
                color: theme.palette.text.secondary,
                backgroundColor: theme.palette.secondary.main,
                "&:hover": {
                    backgroundColor: theme.palette.secondary.dark
                },
                ...props.sx
            }}
        >
            {props.children}
        </PrimaryButton>
    )
}
