import { useState } from "react"

import { ArticleSearchFilter } from "../../../../../../../api/deepSearchAPI.types"

export const useFilters = () => {
    const [filterList, setFilterList] = useState<Array<ArticleSearchFilter>>([])

    // Function to update filterList
    // - Adds new filter if it does not exist
    // - Adds and removes new filter values
    // - Removes entire filter entry if there are no filter-values for that specific filter
    // - Filters call this function and inject their state.
    // - The current filters in filterList are always overridden by a new array
    const updateFilter = (articleSearchFilter: ArticleSearchFilter) => {
        const { columnName, value } = articleSearchFilter

        const findIndex = filterList.findIndex(
            (filter) => filter.columnName === columnName
        )

        // utility function to add filters
        const updateEntry = (
            filter: ArticleSearchFilter,
            currentIndex: number,
            findIndex: number
        ) => {
            if (currentIndex === findIndex) {
                return {
                    ...filter,
                    value: [...value]
                }
            }

            return filter
        }

        // columnName found
        // update value field (filters)
        if (findIndex > -1) {
            let updatedFilters: Array<ArticleSearchFilter>

            // if there are filter values
            // update current filters
            if (value.length > 0) {
                updatedFilters = filterList.map((currentFilter, currentIndex) =>
                    updateEntry(currentFilter, currentIndex, findIndex)
                )

                // if there are no filter values
                // remove entire filter entry
            } else {
                updatedFilters = filterList.filter(
                    (filter) => filter.columnName !== columnName
                )
            }

            setFilterList(updatedFilters)
            // columnName not found
            // create filter entry with given filter values
        } else {
            setFilterList((currentFilters) => [
                ...currentFilters,
                articleSearchFilter
            ])
        }
    }

    const resetFilters = () => {
        setFilterList([])
    }

    return { filterList, updateFilter, resetFilters }
}
