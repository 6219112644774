import { Button, ButtonProps } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"

import { useCustomColors } from "../theme/themes.hooks"

export const PrimaryButton = (props: ButtonProps) => {
    const theme = useTheme()
    const customColors = useCustomColors()
    return (
        <Button
            {...props}
            variant="contained"
            disableFocusRipple
            disableTouchRipple
            disableElevation
            disableRipple
            sx={{
                height: "2rem",
                minWidth: "11rem",
                borderRadius: 0,
                textTransform: "initial",
                color: customColors.white,
                backgroundColor: theme.palette.primary.main,
                "&:hover": {
                    backgroundColor: theme.palette.primary.dark
                },
                ...props.sx
            }}
        >
            {props.children}
        </Button>
    )
}
