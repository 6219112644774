import { PrimaryButton } from "@fluentui/react"
import { Stack, Typography } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"

import { InfoLayout } from "../../../../../../common/InfoLayout"

export function NoArticlesFound() {
    const navigate = useNavigate()
    const { t } = useTranslation()

    const restartConfigurator = async () => {
        navigate(0)
    }

    return (
        <InfoLayout>
            <Stack direction="column" alignItems="center">
                <Typography>{t("common:noArticlesFound")}</Typography>
                <PrimaryButton
                    onClick={restartConfigurator}
                    styles={{
                        root: {
                            marginTop: "2em",
                            padding: "1em",
                            borderRadius: 0
                        },
                        flexContainer: {
                            flexDirection: "row-reverse",
                            justifyContent: "space-between"
                        }
                    }}
                    text={t("common:restartConfiguration").toString()}
                />
            </Stack>
        </InfoLayout>
    )
}
