import { ContainerTO, ParameterTO } from "@encoway/c-services-js-client"
import { countNotReadyChildren } from "@encoway/cui-configurator-components"

import { getSubConfigurationId } from "./productselection/selection"

export const isTabReady = (tab: ContainerTO) => {
    // check if the tab is a "product selection" tab. If so, we have to check if a product is selected for this tab
    const parameters = parametersWithProductSelectionViewport(tab)
    const isProductSelectionTab = parameters.length > 0
    if (isProductSelectionTab) {
        const parameterWithSubConfiguration = parameters.find(
            (p) => getSubConfigurationId(p) !== null
        )

        return Boolean(
            parameterWithSubConfiguration &&
                hasSelectedProduct(parameterWithSubConfiguration)
        )
    }
    // otherwise, check if there are any "not ready" parameters
    return countNotReadyChildren(tab) === 0
}

export const isSelectedTabReady = (selectedTab: ContainerTO) => {
    // if the selected tab is a "product selection" tab, we have to check if a product was selected
    // (which means it is present in the selectedProducts-State)
    const parameterWithSubConfiguration =
        parametersWithProductSelectionViewport(selectedTab).find(
            (p) => getSubConfigurationId(p) !== null
        )

    if (parameterWithSubConfiguration) {
        return hasSelectedProduct(parameterWithSubConfiguration)
    }

    return countNotReadyChildren(selectedTab) === 0
}

const parametersWithProductSelectionViewport = (container: ContainerTO) =>
    container.parameters.filter((p) => p.viewPort === "productselection")

const hasSelectedProduct = (parameterTO: ParameterTO) =>
    Boolean(parameterTO.properties?.piid)
