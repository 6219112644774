import {
    concatStyleSets,
    getTheme,
    Icon,
    IRawStyle,
    Link,
    registerIcons,
    Stack
} from "@fluentui/react"
import { ChevronLeftIcon, ChevronRightIcon } from "@fluentui/react-icons-mdl2"
import { mergeStyles } from "@fluentui/react/lib/Styling"
import { Text } from "@fluentui/react/lib/Text"
import { IRenderFunction } from "@fluentui/react/lib/Utilities"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
    ContentProps,
    TabsFooter,
    TabsFooterProps
} from "@encoway/cui-configurator-components"
import { L10n } from "@encoway/l10n"

import { selectSelectedTab } from "../../../../../app/configuratorSlice"
import { useAppSelector } from "../../../../../app/hooks"
import { useCustomColors } from "../../../../../theme/themes.hooks"
import { isSelectedTabReady } from "./readyState"

const CHEVRON_LEFT = "ChevronLeft"
const CHEVRON_RIGHT = "ChevronRight"

registerIcons(
    {
        icons: {
            [CHEVRON_LEFT]: <ChevronLeftIcon />,
            [CHEVRON_RIGHT]: <ChevronRightIcon />
        }
    },
    { disableWarnings: true }
)

export const MyTabsFooter = (props: TabsFooterProps) => {
    const { isFirstTab, isLastTab, data } = props

    const selectedTab = useAppSelector(selectSelectedTab(data))

    const canJumpToNextTab = selectedTab && isSelectedTabReady(selectedTab)

    const theme = getTheme()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const customColors = useCustomColors()

    const commonFooterStyle = (
        textColor?: string,
        bgColor?: string,
        bgColorHover?: string
    ): IRawStyle => {
        return {
            button: {
                transition: "all .3s ease-in-out",
                textDecoration: "none !important",
                fontSize: "1rem",
                fontWeight: "400",
                textTransform: "capitalize",
                letterSpacing: "1px",
                userDrag: "none",
                userSelect: "none",
                color: `${textColor} !important`,
                backgroundColor: bgColor,
                border: 0
            },
            "button:hover": {
                backgroundColor: bgColorHover
            },
            "button:focus": {
                backgroundColor: bgColorHover,
                outline: 0
            },
            "button > span": {
                position: "relative",
                top: "-1px"
            },
            "button i span": {
                width: "0.7em",
                height: "0.7em",
                marginTop: "-0.2em"
            }
        }
    }

    const onRender: IRenderFunction<ContentProps> = (contentProps) => {
        if (!contentProps) {
            return null
        }

        const { classNames, data, onRenderPreviousButton, changeTab } =
            contentProps

        const rootStyles = { borderTop: `1px solid ${customColors.lightGray}` }

        return (
            <Stack
                horizontal
                className={mergeStyles(classNames.footer, {
                    width: "100% !important",
                    left: "0 !important",
                    right: "0 !important",
                    zIndex: "9999 !important",
                    maxWidth: "1600px !important",
                    padding: "1rem 2em !important"
                })}
                styles={{
                    root: rootStyles
                }}
            >
                <div className={classNames.footerLeft}>
                    {!isFirstTab &&
                        onRenderPreviousButton({
                            onClick: () => changeTab(-1),
                            data,
                            classNames
                        })}
                </div>

                <div className={classNames.footerRight}>
                    {isLastTab ? (
                        /* On the last tab we want to navigate to the composition page */
                        <Link
                            className={classNames.footerLink}
                            disabled={!canJumpToNextTab}
                            onClick={() => navigate("/composition")}
                        >
                            <Text nowrap>{t("common:composition")}</Text>
                        </Link>
                    ) : (
                        <Link
                            className={classNames.footerLink}
                            disabled={!canJumpToNextTab}
                            onClick={() => {
                                changeTab(1)
                            }}
                        >
                            <Text nowrap>
                                {L10n.format(
                                    "Configuration.TabsFooter.Next",
                                    {}
                                )}
                            </Text>
                            <Icon iconName={CHEVRON_RIGHT} />
                        </Link>
                    )}
                </div>
            </Stack>
        )
    }

    return (
        <TabsFooter
            {...props}
            onRender={onRender}
            styles={{
                footer: {
                    position: "fixed",
                    bottom: 0,
                    left: 52, // 52px - sum of padding left (to maintain an equal space to left paddings)
                    right: 52, // 52px - sum of padding right (to maintain an equal space to right paddings)
                    maxWidth: "1496px", // maxWidth of CUI container (1600px) minus the sum of paddings for each side (2*52px = 104px)
                    padding: "1rem 0",
                    margin: "0 auto",
                    backgroundColor: customColors.white
                },
                footerLeft: concatStyleSets(
                    commonFooterStyle(
                        customColors.almostBlack,
                        customColors.lightGray,
                        customColors.grayButtonBgHover
                    ),
                    {
                        flexDirection: "row-reverse",
                        align: "right",
                        button: {
                            float: "right",
                            padding: ".413rem 1.75rem .875em 1.325rem"
                        },
                        "button i span": {
                            marginRight: "1.25em"
                        }
                    }
                ),
                footerRight: concatStyleSets(
                    commonFooterStyle(
                        theme.palette.white,
                        theme.palette.themePrimary,
                        theme.palette.themeDark
                    ),
                    {
                        flexGrow: 0,
                        button: {
                            marginLeft: "2rem",
                            padding: ".413rem 1.325rem .875rem 1.75rem"
                        },
                        "button i span": {
                            marginLeft: "1.25em"
                        },
                        "button:disabled": {
                            color: `${customColors.grayButtonBgHover} !important`,
                            backgroundColor: customColors.lightGray
                        }
                    }
                )
            }}
        />
    )
}
