import {
    CompositionAccessoryGroup,
    CompositionProduct
} from "../composition.types"

export const getTotalArticleCount = (
    selectedProducts: Array<CompositionProduct> = [],
    accessoryGroups: Array<CompositionAccessoryGroup> = []
) => {
    const productSize = selectedProducts.length
    const accessorySize = accessoryGroups
        .map((group) => group.accessories)
        .flat(1).length

    return productSize + accessorySize
}
