import React from "react"
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"

import { App } from "./App"
import { initI18n } from "./app/i18n"
import { store } from "./app/store"
import { SSO } from "./services/authenticationClient.types"

declare global {
    interface Window {
        sso: SSO
        configurator: {
            render: typeof render
        }
    }
}

export const render = () => {
    const container = document.getElementById("root")
    if (container) {
        const root = createRoot(container)

        initI18n()

        return root.render(
            <Provider store={store}>
                <App />
            </Provider>
        )
    }
}

render()
