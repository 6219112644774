import { useEffect, useState } from "react"

export const useScrollTargetReached = (
    elementId: string,
    offsetInPixel: number
) => {
    const [targetBottomReached, setTargetBottomReached] = useState(false)

    useEffect(() => {
        // Element that is queried for its lower bound
        const element = document.getElementById(elementId)

        const updateBottomReached = () => {
            if (!element) {
                console.warn(errorMessages.notFound(elementId))
                return
            }

            const bottomReached =
                element.getBoundingClientRect().bottom + offsetInPixel <=
                window.innerHeight

            setTargetBottomReached(bottomReached)
        }

        window.addEventListener("scroll", updateBottomReached)
        return () => window.removeEventListener("scroll", updateBottomReached)
    }, [window.scrollY])

    return targetBottomReached
}

const errorMessages = {
    notFound: (elementId: string) =>
        `WARNING: DOM-Element with id ${elementId} could not be found. (useScrollTargetReached)`
}
