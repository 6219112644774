import { useContext } from "react"

import { ParameterTO } from "@encoway/c-services-js-client"

import { useSelectProductMutation } from "../../../../../../api/deepSearchAPI"
import { assertNotNull } from "../../../../../../app/assertions"
import { ProductSelectionContext } from "./ProductSelectionContext"

export const useProductSelection = (piid: string, supplierName: string) => {
    const [selectProductMutation, { isError, error, isLoading }] =
        useSelectProductMutation()
    const { subConfigurationId, parameterTO } = useContext(
        ProductSelectionContext
    )
    assertNotNull(subConfigurationId, "subConfigurationId is null")
    assertNotNull(parameterTO, "parameterTO is null")

    const select = async () => {
        try {
            await selectProductMutation({
                configurationId: subConfigurationId,
                body: {
                    manufacturerId: piid.split("|")[0],
                    manufacturerPid: piid.split("|")[1],
                    supplierName
                }
            }).unwrap()
        } catch (e) {
            console.log("error selecting product", error)
        }
    }

    return {
        selectProduct: select,
        isError,
        isLoading,
        isProductSelected: productSelected(parameterTO, piid)
    }
}

const productSelected = (parameterTO: ParameterTO, piid: string) =>
    parameterTO.properties?.piid === piid
