import { Box, CircularProgress, Popover, Typography } from "@mui/material"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

import { useCustomColors } from "../../theme/themes.hooks"

type Anchor = HTMLButtonElement | null

const Loading = () => {
    const { t } = useTranslation()
    return (
        <Box display="flex" justifyContent="center" alignItems="center">
            <CircularProgress
                size={25}
                sx={{ color: "white", marginLeft: "1em" }}
            />
            <Typography sx={{ p: 2 }}>
                {t("glossary:header:loadConfigurationLoading")}
            </Typography>
        </Box>
    )
}

const SuccessMessage = () => {
    const { t } = useTranslation()
    return (
        <Box sx={{ padding: "1em" }}>
            <Typography>
                {t("glossary:header:loadConfigurationSuccess")}
            </Typography>
        </Box>
    )
}

const ErrorMessage = () => {
    const { t } = useTranslation()
    return (
        <Typography sx={{ padding: "1em" }}>
            {t("glossary:header:loadConfigurationFailed")}
        </Typography>
    )
}

type Props = {
    anchor: Anchor
    setAnchor: Dispatch<SetStateAction<Anchor>>
    loading: boolean
    error: boolean
}

export const ConfiCodeLoadPopover = (props: Props) => {
    const { anchor, setAnchor, loading, error } = props
    const customColors = useCustomColors()
    const open = Boolean(anchor)

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left"
            }}
        >
            <Box
                sx={{
                    color: "white",
                    backgroundColor: `${customColors?.headerPopoverBackground}`
                }}
            >
                {(error && <ErrorMessage />) ||
                    (loading && <Loading />) ||
                    (!loading && !error && <SuccessMessage />)}
            </Box>
        </Popover>
    )
}
