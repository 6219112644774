import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

import UserService from "../services/userService"
import { LoadConfigurationResult } from "./configurationCodes.types"

export const configurationCodesAPI = createApi({
    reducerPath: "configurationCodesAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: "/api/configuration/configurations/codes",
        prepareHeaders: (headers: Headers) => {
            headers.set(
                "Authorization",
                `Bearer ${UserService.getAccessTokenFromStorage()}`
            )
        },
        credentials: "include"
    }),
    endpoints: (builder) => ({
        createConfigurationCode: builder.mutation<string, string>({
            query: (configurationId) => ({
                method: "POST",
                url: `/create/${configurationId}`
            })
        }),
        loadConfigurationCode: builder.mutation<
            LoadConfigurationResult,
            { configurationId: string; configurationCodeId: string }
        >({
            query: ({ configurationId, configurationCodeId }) => ({
                method: "POST",
                url: `/load/${configurationId}/${configurationCodeId}`
            })
        })
    })
})

export const {
    useCreateConfigurationCodeMutation,
    useLoadConfigurationCodeMutation
} = configurationCodesAPI
