import { Stack, StackItem, VerticalDivider } from "@fluentui/react"
import React from "react"

import { Section, SectionProps } from "@encoway/cui-configurator-components"

import { useCustomColors } from "../../../../../theme/themes.hooks"
import { LeftOrRight, useSplitLayout } from "./MySection.hooks"

const DualLayout = (props: SectionProps) => {
    const customColors = useCustomColors()
    const { filterChildrenBySplitSide } = useSplitLayout()

    const propsForSide = (side: LeftOrRight) => ({
        ...props,
        data: {
            ...props.data,
            children: filterChildrenBySplitSide(props.data.children, side)
        }
    })

    const propsRight = propsForSide("RIGHT")
    const propsLeft = propsForSide("LEFT")

    return (
        <Stack
            horizontal
            style={{
                paddingTop: "1.5em",
                paddingBottom: "7rem", // offset for absolute positioned TabsFooter
                width: "100%"
            }}
        >
            <StackItem
                style={{
                    flex: 1,
                    paddingRight: "3em"
                }}
            >
                <Section {...propsLeft} />
            </StackItem>
            <StackItem>
                <VerticalDivider color={customColors.lightGray} />
            </StackItem>
            <StackItem
                style={{
                    flex: 1,
                    paddingLeft: "3em"
                }}
            >
                <Section {...propsRight} />
            </StackItem>
        </Stack>
    )
}

export const MySection = (props: SectionProps) => {
    const { isSplitLayoutContentBlock, shouldDisplayLabel } = useSplitLayout()

    if (isSplitLayoutContentBlock(props.data)) {
        return <DualLayout {...props} hideLabel={true} />
    }

    return <Section {...props} shouldDisplayLabel={shouldDisplayLabel} />
}
