import { getTheme, ITheme, mergeStyleSets } from "@fluentui/react"
import { CheckMarkIcon } from "@fluentui/react-icons-mdl2"
import classNames from "classnames"
import React from "react"

function checkMarkStyles(theme: ITheme) {
    return mergeStyleSets({
        circle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "18px",
            height: "18px",
            border: "2px solid",
            borderRadius: "50%",
            borderColor: theme.palette.themePrimary,
            backgroundColor: theme.palette.white,
            selectors: {
                "&.is-ready": {
                    backgroundColor: theme.palette.themePrimary
                }
            }
        },
        checkMark: {
            fontSize: "12px",
            color: theme.palette.white,
            selectors: {
                "&.is-active": {
                    color: theme.palette.themePrimary
                }
            }
        }
    })
}

type Props = {
    isReady: boolean
    active?: boolean
}

export const MyTabCheckmark = ({ isReady, active = false }: Props) => {
    const theme = getTheme()
    const { circle, checkMark } = checkMarkStyles(theme)

    return (
        <div className={classNames(circle, { "is-ready": isReady && !active })}>
            {isReady && (
                <CheckMarkIcon
                    className={classNames(checkMark, {
                        "is-active": active
                    })}
                />
            )}
        </div>
    )
}
